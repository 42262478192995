@import "src/styles/variables";
@import "src/styles/mixins";

.project-header {
  position: sticky;
  z-index: 10;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  border-bottom: 1px solid rgba(27, 25, 23, 0.1);
  background-color: $neutral0;

  &__top,
  &__bottom {
    display: flex;
    align-items: center;
    height: 56px;
  }

  &__top {
    .ant-btn-circle {
      margin-right: 16px;
      background-color: transparent;
    }
  }

  &__title {
    @include h4-regular($neutral5);
  }

  .project-nav {
    display: flex;
    align-items: center;

    &__item {
      position: relative;
      a {
        @include overline($neutral4);
        text-transform: uppercase;

        &.active {
          font-weight: 700;
          color: $neutral5;

          &:before {
            content: '';
            position: absolute;
            height: 2px;
            width: 100%;
            bottom: -19px;
            background-color: $neutral5;
          }
        }
      }

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}
