@import "src/styles/variables";
@import "src/styles/mixins";

.gantt-user {
  display: flex;
  width: 100%;

  .ccm-avatar {
    margin-right: 16px;
  }

  .avatar-info {
    align-items: flex-start;

    &__name {
      margin-bottom: 8px;
      @include h4-regular($neutral5);
    }
  }

  &:not(:last-child) {
    margin-bottom: 35px;
  }

  &__value {
    margin-left: auto;
    font-weight: 300;
    font-size: 56px;
    line-height: 64px;
    color: $red3;
  }
}
