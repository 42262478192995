@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'ccm-element-type';

.#{$name} {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: $neutral1;
  border-radius: 50%;

  &--tooltip {
    &:hover {
      cursor: pointer;
    }
  }

  & svg,
  & path {
    color: $neutral3;
    fill: $neutral3;
  }

  &--violet {
    background-color: $blue1;

    & svg,
    & path {
      color: $blue3;
      fill: $blue3;
    }
  }

  &--green {
    background-color: $green1;

    & svg,
    & path {
      color: $green3;
      fill: $green3;
    }
  }
}
