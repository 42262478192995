@import "src/styles/variables";

$name: 'issue-point';

.#{$name} {
  position: absolute;
  z-index: 100;
  width: 16px;
  height: 16px;
  border: 1px solid $normal-6;
  background-color: $normal-1;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }

  &--new {
    border-color: $red-6;
  }
  &--new.#{$name}--active {
    background-color: $red-6;
  }

  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $normal-6;

    .#{$name}--new & {
      background-color: $red-6;
    }
    .#{$name}--new.#{$name}--active & {
      background-color: $normal-1;
    }
  }


}
