@import "src/styles/variables";
@import "src/styles/mixins";

.course-page-about {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 840px;
  height: 100%;
  padding: 32px;

  .add-course-form {
    height: auto;
  }

  & .course-page-about {
    &__description {
      display: flex;
      flex-direction: column;
      margin-bottom: 48px;

      .add-course-form__title {
        margin-bottom: 16px;
      }

      .ccm-date-picker--production {
        margin-right: 8px;
      }

      .ccm-select--timeZone {
        margin-left: 8px;
      }
    }
  }

  & .ccm-date-picker {
    & .ant-form-item-no-colon {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $neutral3;
    }
  }

  &__title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $neutral3;
    margin-bottom: $space-2;
  }

  &__row {
    display: flex;
    width: 100%;
    //justify-content: space-between;
  }

  &__description {
    width: 100%;
    min-width: 376px;

    &--normal {
      .course-page-about__value {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $neutral5;
      }
    }

    &--large {
      .course-page-about__value {
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: $neutral5;
      }
    }
  }

  & .ant-input-lg {
    height: 30px;
  }

  &__select {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  & .status-flat {
    padding-left: 0;
    text-transform: uppercase;
  }
}
