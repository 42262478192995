@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'ccm-date-picker';

.#{$name} {
  width: 100%;
  font-family: 'PT Root UI', sans-serif;

  & .ant-form-item-label > label {
    @include description-regular($neutral3);
  }

  & .ant-picker,
  &.ant-picker {
    background-color: $neutral1;
    border-color: $neutral1;

    .ant-picker-suffix {
      color: $neutral3;
    }

    input {
      font-family: 'PT Root UI', sans-serif;
      @include footnote($neutral5);

      &::placeholder {
        font-family: 'PT Root UI', sans-serif;
        @include footnote($neutral3);
      }
    }

    &:hover {
      border-color: $neutral3;
      box-shadow: none;
    }
  }

  & .ant-picker-large,
  &.ant-picker-large {
    padding: 6.5px 11px;

    input {
      font-size: 14px;
      line-height: 20px;

      &::placeholder {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &.ant-picker-xl {
    min-height: 48px !important;
    border-radius: 8px !important;
    padding-left: 22px !important;
    padding-top: 0;
    padding-bottom: 0;

    input {
      height: 46px;
      font-size: 16px;
      line-height: 24px;

      &::placeholder {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &.ant-picker-status-warning {
    background-color: $orange1;
    border-color: $orange1;
  }

  &.ant-picker-status-error {
    background-color: $red1;
    border-color: $red1;
  }

  &.ant-picker-focused {
    background-color: $white;
    border-color: $neutral1;
    box-shadow: none;
  }

  &.ant-picker-disabled {
    background-color: $neutral0 !important;
    border-color: $neutral1 !important;

    :hover {
      border-color: $neutral1;
    }

    .ant-picker-suffix {
      color: $neutral2;
    }

    input {
      color: $neutral2;

      &::placeholder {
        color: $neutral2;
      }
    }
  }
}

button.ant-picker-header-super-prev-btn,
button.ant-picker-header-prev-btn,
button.ant-picker-header-next-btn,
button.ant-picker-header-super-next-btn, {
  color: $neutral3;
}

.ant-picker-header-view {
  color: $neutral5;
  font-weight: 400;
}

.ant-picker-body {
  .ant-picker-content th {
    color: $neutral3;
    text-transform: capitalize;
    font-size: 14px;
    line-height: 20px;
  }

  tbody {
    .ant-picker-cell {
      font-size: 13px;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
      border-radius: 2px;
    }
  }
}

.ant-picker-today-btn,
.ant-picker-now {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: $neutral5;
  text-transform: uppercase;
}

.ant-picker-ok {
  button {
    border-radius: 40px;
  }
}

.ant-picker-time-panel {
  padding: 4px 0 33px 0;

  .ant-picker-header {
    display: none;
  }

  .ant-picker-time-panel-column {
    @include scrollHidden();

    .ant-picker-time-panel-cell {
      font-size: 14px;
      line-height: 20px;
    }
  }
}


