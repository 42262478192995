 @import "src/styles/variables";
@import "src/styles/mixins";

$name: 'dropdown-checkbox';

.#{$name} {
  .anticon-down {
    width: 10px;
    height: 10px;
    transition: 0.3s;
  }
  &--opened {
    .anticon-down {
      transform: rotate(180deg);
      transition: 0.3s;
    }
  }

  .ant-dropdown-trigger {
    border: 1px solid $neutral2;
    color: $neutral5;
    font-weight: 400;
    text-transform: capitalize;

    &:hover {
      color: $neutral4;
      border-color: $neutral4;
    }

    &:disabled {
      background-color: $white;
      color: $neutral2;
      border-color: $neutral2;
    }
  }

  .ant-btn,
  .ant-btn-sm {
    .#{$name}__value {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .ant-btn {
    padding: 0 16px;
  }

  .ant-btn-sm {
    padding: 0 12px;
  }

  .ant-btn-lg {
    padding: 0 24px;
    .#{$name}__value {
      font-size: 14px;
      line-height: 20px;
    }
  }


  &-menu {

    &-list {
      display: flex;
      flex-direction: column;
      background-color: $white;
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      border-radius: 8px;

      .ant-checkbox-group-item {
        margin-right: 0;

        &:hover {
          background-color: $neutral1;
        }
      }

      .ant-checkbox-wrapper {
        height: 32px;
        padding: 5px 12px;

        .ant-checkbox-inner {
          border-radius: 0;
        }

        //
        &:hover .ant-checkbox-inner {
          border-radius: 0;
        }

        span:last-child {
          font-size: 13px;
          line-height: 22px;
          color: $neutral5;
        }
      }
    }
  }

  &--drop-checkbox-list {
    .ant-btn-sm {
      display: inline-flex;
      align-items: center;

      .anticon-down {
        height: 10px;
        transition: 0.2s;
      }
    }
  }

  &--drop-checkbox-list--opened {
    .anticon-down {
      transform: rotate(-180deg);
      transition: 0.2s;
    }
  }

}
