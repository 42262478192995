@import "src/styles/variables";
@import "src/styles/mixins";

.ant-popover {
  & .ant-popover-title {
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 28px !important;
    color: $neutral5 !important;
    border-bottom: none !important;
    padding: $space-2 $space-4;
  }

  & .ant-popover-buttons {
    display: flex;
  }

  & .ant-popover-inner-content {
    @include description-short($neutral3);
  }
}
