@import "src/styles/variables";
@import "src/styles/mixins";

.user-element {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 48px;
  padding: 0 16px 0 12px;
  border: 1px solid $main;
  background-color: $white;
  border-radius: 8px;

  &-wrapper {
    display: flex;
    flex-direction: column;

    &--m {
      height: 80px;
    }

    &--l {
      height: 96px;
    }

    &--xl {
      height: 120px;
    }
  }

  &__link {
    padding: 0 20px;
    display: inline-flex;
    align-items: center;
    @include overline-bold($neutral5);
    &-text {
      margin-right: 6px;
    }
  }

  &:hover {
    cursor: pointer;
    border-color: $neutral5;
  }
  &--disabled {
    cursor: auto !important;
    border-color: $neutral2 !important;
    background-color: $neutral0;
  }

  &--l {
    height: 64px;
    padding-right: 24px;
    border-radius: 12px;
  }

  &--xl {
    height: 88px;
    padding-right: 24px;
    border-radius: 16px;
  }

  .ccm-avatar {
    margin-right: 16px;
  }

  &__wrap {
    display: inline-flex;
    flex-direction: column;
  }

  &__company {
    @include description-regular($neutral3);
  }

  &__name {
    @include body-regular($neutral5);
    max-width: 240px;
    text-overflow: ellipsis;
    overflow: hidden;

    .user-element--l & {
      @include h5-regular($neutral5);
    }

    .user-element--xl & {
      @include h5-regular($neutral5);
    }

    &-wrap {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;

      .status-flat__value {
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }

  &__timezone {
    @include description-regular($neutral4)
  }
}
