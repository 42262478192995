@import "src/styles/variables";
@import "src/styles/mixins";

.drawer-view-employee {
  .action-bar {
    display: inline-flex;
    align-items: center;

    .button-block {
      color: $red3;

      &:hover {
        background-color: $red1;
        color: $red2;
      }
    }

    .button-send,
    .button-edit {
      &:hover {
        color: $neutral3;
      }
    }
  }

  .ant-drawer-body {
    padding: 0;
  }

  .view-employee {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    padding: 16px 32px;

    & .status-flat__value {
      text-transform: uppercase;
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;

      .ant-avatar-string {
        font-size: 40px;
        color: $neutral5;
      }
    }

    &__photo {
      max-width: 200px;
      max-height: 200px;
      margin-bottom: 32px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__name {
      font-size: 32px;
      line-height: 48px;
      color: $neutral5;
      margin-bottom: 12px;
    }

    &__status {
      display: inline-flex;
      align-items: center;

      &-time {
        text-transform: uppercase;
        @include description-regular($neutral4);
      }
    }
  }

  .form-add_employee {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    padding: 16px 32px;
  }

}

.role-prod-popover {
  .ant-popover-inner-content {
    padding: 0;
  }

  .role-prod-paragraph {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    padding: 8px 16px;

    &__title {
      @include body-regular($neutral5);
    }

    &__text {
      @include description-regular($neutral3);
    }
  }
}

.role-prod-view {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 8px;
}




