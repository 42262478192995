@import "src/styles/variables";
@import "src/styles/mixins";

.view-free-task-page {
  display: grid;
  grid-template-columns: 1fr 560px;
  grid-template-rows: 56px 1fr;
  width: 100%;
  row-gap: 16px;

  .status-flat__value {
    width: fit-content !important;
  }

  .header-small {
    grid-row: 1 / 2;
    grid-column: 1 / -1;
  }

  &__content {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    padding-left: 32px;
    padding-bottom: 32px;
    height: calc(100vh - 56px - 16px);
    padding-right: 8px;
    overflow-y: auto;
    @include scrollSmall();
  }

  &__panel {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    height: calc(100vh - 56px - 16px);
    padding-right: 8px;
    overflow-y: auto;
    @include scrollSmall();
  }

  .status-flat {
    padding: 0;
  }

  .dropdown-status {
    padding: 8px 16px;
    border: 1px solid $neutral2;
    border-radius: 16px;
  }

  &__editor-wrap {
    position: relative;
    display: flex;
    flex-direction: column;

    .editor-expand {
      position: absolute;
      right: 0;
      top: 32px;
      z-index: 2;
    }

    .editor .codex-editor__redactor {
      min-height: 160px;
      padding: 12px 56px 24px 16px !important;
      font-size: 16px;
      line-height: 24px;
      border-radius: 8px;
      background-color: $white;
    }
  }

  &__label {
    margin-bottom: 12px;
    @include overline($neutral3);
  }
}
