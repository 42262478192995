@import "src/styles/variables";
@import "src/styles/mixins";

.page-dashboard {
  min-width: 1366px;

  .loading-overlay {
    position: fixed;
  }

  &__empty-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $neutral1;
    height: 320px;
    border-radius: 8px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 32px 32px 0 32px;
  }

  &-widgets {
    display: flex;
    align-items: center;
    width: 100%;

    .dashboard-widget {
      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }

  .skeleton-dashboard {
    padding: 32px;

    &__row {
      display: flex;
      flex-direction: column;

      .ant-skeleton-title {
        height: 24px;
        margin-bottom: 0;
      }
    }

    &__row-b {
    }

    &__row-s {
      .ant-skeleton {
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }

      .ant-skeleton-title {
        height: 40px;
        margin-bottom: 0;
      }
    }

    &__row-sub {
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 16px;

      .ant-skeleton-title {
        height: 88px !important;
        margin-bottom: 0;
      }
    }
  }
}
