
.global-sett {
  &-page {
    display: flex;
    flex-direction: column;
  }

  &__top {
    display: flex;
    align-items: center;
    column-gap: 64px;
    margin-bottom: 32px;
  }

  &-form {
    display: grid;
    grid-template-columns: 400px 400px;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 32px;

    &__footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}
