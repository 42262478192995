@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'sidebar';

.#{$name} {
  &__info {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    margin-bottom: 24px;

    &-link {
      @include body-regular($neutral5);
    }

    &__title {
      @include h4-regular($neutral5);
    }

    &__description {
      @include footnote($neutral3)
    }

    &__text {
      word-wrap: break-word;
      @include body-regular($neutral5);
    }
  }

  &__header-content {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 10px 0;
  }

  &__header-info {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &__header-human-number {
    margin-right: $space-2;
    @include description-regular($neutral7);
  }


  &__info-menu {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  &__menu-item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 15px 12px 24px;

    a:hover {
      text-decoration: none;
      color: $neutral5;
    }

    &-number {
      &-iteration {
        @include description-regular($neutral7);
        margin-right: 12px;
      }
    }

    &--active {
      background-color: $normal-1;

      &:after {
        position: absolute;
        right: 0;
        content: '';
        width: 4px;
        height: 100%;
        background-color: $neutral5;
      }
    }

    &-name {
      display: flex;
      align-items: center;
      width: 179px;
      @include description-regular($neutral5);
      align-content: center;
      height: 24px;

      &-container {
        padding: 3px 12px;
        gap: 8px;
        background: $neutral1;
        border-radius: 2px;
        @include description-regular($neutral5);
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-link {
      width: 100%;
      @include description-regular($neutral5);
      text-transform: capitalize;
      display: flex;
      flex-direction: row;
      align-items: center;

      & .status-flat {
        padding-left: 0;

        & .dot {
          height: 8px;
          width: 8px;
        }
      }

      .#{$name}__menu-item--active & {
        color: $neutral5
      }
    }

    &-date {
      width: 100%;
      max-width: 48px;
      @include description-regular($neutral5);
      min-width: 57px;

      &-iteration {
        @include description-regular($neutral7);
        //color: $normal-7;
        margin-right: 20px;
        min-width: 57px;
      }
    }

    &-label {
      width: 100%;
      max-width: 85px;
    }

    &-file {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      width: 24px;
      height: 24px;
      @include text($neutral5);

      .#{$name}__menu-item--active & {
        color: $neutral5;
      }
    }

    & .status-round {
      min-width: 40px;
    }
  }

  & .assigners-button {
    & .ccm-button {
      height: 22px;
      color: $neutral5;
    }

    &__text {
      margin-right: $space-2;
    }
  }

  .all-participants {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}
