@import "src/styles/variables";
@import "src/styles/mixins";

.ant-input-number,
.ccm-input-number {
  border-radius: 8px;
  box-shadow: none;



  .ant-input-number-handler-wrap {
    display: none;
  }

  input {
    font-family: 'PT Root UI', sans-serif;
    height: 46px;
    padding: 11px 12px 11px 16px;
    font-size: 16px;
    line-height: 24px;

    &::placeholder {
      color: $neutral3 !important;
    }
  }

  &--xl {
    .ant-input-number-input {
      padding: 11px 12px 11px 16px;
      font-size: 16px;
      line-height: 24px;
      border-radius: 8px;
      height: auto !important;
    }
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px $neutral1 inset !important;
  }
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px $white inset !important;
    transition: all 0.3s;
  }

  background-color: $neutral1;
  border-color: $neutral1;

  &:hover {
    border-color: $neutral3;
    box-shadow: none;
  }

  &:focus {
    background-color: $white;
    border-color: $neutral1;
    box-shadow: none;
  }

  &-focused {
    background-color: $white !important;
    border-color: $neutral1;
    box-shadow: none;
  }
}

.ant-input-number-status-error {
  background-color: $red1;
  border-color: $red1;
  color: $red3;

  &:hover,
  &:focus {
    box-shadow: none;
  }
}
