@import "src/styles/variables";

.signup-page {
  //height: 640px;
  width: 66.66%;
  //align-items: center;

  .card-auth__header {
    margin-bottom: 18px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__title {
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
    color: $neutral5;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;

    & .text-field--email,
    & .text-field--first_name,
    & .text-field--last_name,
    & .text-field--confirm_password,
    & .text-field--name,
    & .text-field--password {
      width: 100%;
    }

    .text-field--confirm_password {
      margin-bottom: 21px;
    }
  }

  .button-login {
    width: 100%;
  }

  & .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
}

.signup-login-page {
  width: 33.33%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: $neutral0;
  height: 96vh;

  &__title {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: $neutral5;
  }

  & .ccm-button {
    background-color: transparent;
    width: 161px;
  }
}

.signup-layout {

  &__items {
    width: 66.66%;
    display: flex;
    justify-content: center;
  }
}