@import "src/styles/variables";
@import "src/styles/mixins";

.participants-gantt {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: auto;
  position: relative;

  &-head {
    position: relative;
    height: 16px;
    margin-bottom: 92px;

    &__wrap {
      position: absolute;
      display: flex;
      align-items: center;
    }

    &__item {
      width: 80px;
      min-width: 80px;
      @include description14($neutral3);
      text-transform: capitalize;
    }
  }

  &-user {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;

    &__row {
      position: relative;
      width: 100%;
    }
  }
}
