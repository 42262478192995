@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: $neutral3;
}

.text {
  @include text($neutral3);
  margin-bottom: 18px;
}
