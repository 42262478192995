@import "src/styles/variables";
@import "src/styles/mixins";

.header {
  display: flex;
  height: 112px;
  padding: 24px 32px;

  .ant-skeleton-title {
    height: 24px !important;
    margin-bottom: 0;
  }

  &__border {
    border-bottom: 1px solid $neutral1;
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &__right {
    justify-content: flex-end;
  }

  &__title {
    font-weight: 300;
    font-size: 56px;
    line-height: 64px;
    cursor: $neutral5;
  }
}
