@import "src/styles/variables";
@import "src/styles/mixins";

.ccm-modal {
  position: relative;
  width: auto !important;
  height: auto !important;
  min-width: 320px;
  padding: 0 !important;
  background-color: $normal-1;
  border-radius: 12px;

  &--button-close {
    position: absolute !important;
    top: 8px;
    right: -60px;
  }

  & .ant-modal-body {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .ant-modal-content {
    width: 100%;
    height: 100%;
    box-shadow: none;
    border-radius: 12px;
  }

  &--wrapper {
    border-radius: 12px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //height: 60px;
    padding: 16px 32px;
    //box-shadow: inset 0 -1px 0 #f0f0f0;
    border-radius: 12px;

    .ccm-title {
      color: $neutral5
    }

    .ccm-button {
      color: $black85;
    }
  }

  &__content {
    padding: 20px 80px 24px 80px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 80px;
    height: 52px;
    box-shadow: inset 0 1px 0 #f0f0f0;

    &-form {
      padding: 0;
      height: auto;
      box-shadow: none;
    }

    & .ccm-button--default {
      margin-right: 12px;
      background-color: $ccm-blue-1;
    }
  }
}

.ccm-modal--confirm {
  min-width: 420px;
}

.ccm-modal--course-preview {
  width: 1080px !important;
  max-height: 90vh !important;
}
