@import "src/styles/variables";
@import "src/styles/mixins";

.choose-export-video {
  display: inline-flex;
  align-items: center;

  &__format {
    margin-right: 16px;
    @include description-regular($neutral5);
  }
}
