@import "src/styles/variables";
@import "src/styles/mixins";

.flow-custom-node {
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: $white;
  border: 1px solid $neutral3;
  border-radius: 12px;
  min-width: 200px;

  &__label {
    margin-bottom: 8px;
    @include text-bold($neutral5);
  }
}
