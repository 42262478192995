@import "src/styles/variables";
@import "src/styles/mixins";

.info-drawer-work-progress {
  display: flex;
  flex-direction: column;
  padding: 12px 30px 12px 24px;
  background-color: $neutral0;
  border-radius: 6px;

  &__title {
    @include description-regular($neutral3);
  }

  &__row {
    display: flex;
    align-items: center;
  }

  &__label {
    width: 250px;
    @include body-regular($neutral5);
  }

  &__from {
    width: 80px;
    text-align: end;
    @include description-regular($neutral3);
  }

  &__value {
    margin-left: auto;
    @include body-regular($neutral5);

    &-large {
      margin-left: auto;
      @include h3-regular($neutral5);
    }
  }

}
