@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'header-navigation';
$nameMain: 'main_navigation';

.#{$name} {
  display: flex;
  height: 100%;

  &__list {
    display: flex;
  }

  &__item {
    @include text($neutral1);
    margin-right: $space-6;

    &:hover {
      color: $neutral1;
    }
  }

  &__link {
    position: relative;
    display: inline-flex;
    height: 100%;

    @include text($neutral3);

    &:hover {
      @include text($neutral1);
    }

    &.active {
      @include text($neutral1);

      &:after {
        position: absolute;
        content: '';
        bottom: -14px;
        width: 100%;
        height: 2px;
        background-color: $neutral1;
      }
    }
  }
}

.#{$nameMain} {
  display: flex;

  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__item {
    height: 64px;
    display: inline-flex;
    width: 100%;
    align-items: center;

    &:hover {
      cursor: pointer;
      background-color: $neutral1;
    }
  }

  &__link {
    display: inline-flex;
    width: 100%;
    height: 100%;
    align-items: center;
    padding-left: 20px;

    .main-sidebar--opened & {
      padding-left: 20px;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__label {
    min-width: 0;
    margin-left: 20px;
    font-weight: 400;
    font-size: 0;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $neutral5;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 0;

    .main-sidebar--opened & {
      opacity: 1;
      font-size: 13px;
      transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
    }

    .active & {
      font-weight: 700;
    }
  }
}
