@import "src/styles/variables";
@import "src/styles/mixins";

.discussion {
  padding: 16px 24px;
  border: 1px solid $neutral4;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background-color: $white;
    border: 1px solid $neutral1;
  }

  &--selected {
    background-color: $white;
    border: 1px solid $neutral1;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  .discussion-head {
    margin-bottom: 12px;
  }

  &__message {
    margin-bottom: 16px;
    @include body-regular($neutral5);
  }

  &-comment {
    margin-bottom: 16px;
  }
}

.discussion-head {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  &__actions {
    margin-left: auto;

    .btn-more {
      color: $neutral3 !important;
    }
  }
}
