@import "src/styles/variables";
@import "src/styles/mixins";

.delay-tag-ex-text {
  margin-left: 8px;
  @include body-regular();

  &--orange {
    color: $orange3;
  }

  &--red {
    color: $red3
  }

  &--black {
    color: $neutral5;
  }
}
