@import "src/styles/variables";
@import "src/styles/mixins";

.carousel-with-error {
  &-wrapper {
    position: relative;
  }

  .ant-carousel .slick-vertical .slick-slide {
    width: 100%;
  }

  &__content {
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &--handler {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.ccm-carousel-error-mark {
  border: 1px solid $normal-5;

  .slick-slide {
    height: auto;
  }
}

// Carousel control
.carousel-control {
  display: flex;
  width: 100%;
  height: 40px;
  margin-bottom: 16px;

  &__left,
  &__right {
    display: flex;
    flex: 1;
    align-items: center;
  }

  &__right {
    justify-content: flex-end;
  }

  &__slide-text {
    margin-right: $space-4;
    @include text($normal-7)
  }

  &__btn {
    &-slide,
    &-grid {
      width: 40px !important;
      height: 40px !important;
    }

    &--active {
      background-color: $neutral1 !important;
    }
  }

  &__btn-left {
    margin-right: 6px;
  }

  &__input {
    width: 41px !important;
    height: 24px;
    margin-right: $space-1;

    border: 1px solid $normal-5;
    transition: 0.3s;
    @include text();

    & input {
      padding: 0 0 0 6px;
      height: 24px;
    }

    &:hover,
    &:focus {
      border-color: $neutral5;
      //box-shadow: 0 0 0 2px rgba(81, 73, 211, 0.2);
      outline: none;
      transition: 0.3s;
    }
  }

  &__count {
    margin-right: 6px;
    @include text($normal-7)
  }
}

// Popup set error
.popup-set-error-info {
  z-index: 2000;

  .ccm-select {
    width: 100%;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}

//Grid
.grid-presentation {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, 275px);
  overflow-y: auto;
  max-height: calc(100vh - 64px - 72px - 16px - 14px);
  @include scrollSmall();

  &-wrap {
    overflow: hidden;
  }

  .anticon-plus {
    margin-bottom: 8px;
  }
}
