@import "src/styles/variables";
@import "src/styles/mixins";

.video-duration {
  display: inline-flex;
  align-items: center;

  &__time {
    @include description();
  }

  &__separator {
    margin: 0 $space-1;
  }

  &__duration {
    color: $normal-7;
  }
}