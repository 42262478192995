@import "src/styles/variables";
@import "src/styles/mixins";

.avatar-info {
  display: flex;
  align-items: center;

  .ccm-avatar {
    margin-right: 8px;
  }

  &__wrap {
    display: inline-flex;
    flex-direction: column;
  }

  &__name {
    @include description-regular($neutral5);
  }

  &__date {
    @include description-regular($neutral3);
  }
}
