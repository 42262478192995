@import "src/styles/variables";
@import "src/styles/mixins";

.content-block {
  display: flex;
  flex-direction: column;

  &__label-wrap {
    display: inline-flex;
    align-items: center;
    margin-bottom: 8px;

    .ccm-tooltip {
      color: $neutral5;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__label {
    margin-right: 4px;
    @include description-regular($neutral3);
  }

  &__text-wrap {
    display: inline-flex;
    align-items: center;
  }

  &__text {
    margin-right: 4px;
    @include h4-regular($neutral5);

    .content-block--small & {
      @include body-regular($neutral5);
    }
  }
}
