@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'status-row';

.#{$name} {
  position: relative;
  display: inline-flex;
  padding-left: 14px;

  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $normal-5;
  }

  &__text {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  &--cancelled,
  &--blocked,
  &--rejected {
    color: $red-5;

    &:before {
      background-color: $red-5;
    }
  }

  &--ready,
  &--in_progress,
  &--new,
  &--review {
    color: $ccm-blue-6;

    &:before {
      background-color: $ccm-blue-6;
    }
  }

  &--reopen {
    color: $orange-5;

    &:before {
      background-color: $orange-5;
    }
  }

  &--done {
    color: $green-6;

    &:before {
      background-color: $green-6;
    }
  }
}
