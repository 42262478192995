@import "src/styles/variables";
@import "src/styles/mixins";

.resource-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__type {
    margin-bottom: $space-3;
    @include h5-regular($neutral5);
  }

  &__text {
    margin-top: 16px;
    @include text($normal-7);
  }

  & .text-field {
    background-color: $neutral0;
    border-color: $neutral0;

    &:hover {
      background-color: $neutral0;
      border-color: $neutral0;
      box-shadow: none;
    }
  }
}
