
.flow {
  display: flex;
  flex-direction: column;

  &__top {
    display: flex;
    align-items: center;
    column-gap: 32px;

    .ccm-select {
      width: 250px;
    }
  }

  &-list {
    display: inline-flex;
    align-items: center;
    column-gap: 8px;
  }

  &__content {
    height: calc(100vh - 113px - 32px - 48px - 24px);
  }
}
