@import "src/styles/variables";
@import "src/styles/mixins";

.ui-page {
  display: flex;
  flex-direction: column;
  padding: 16px 48px;
  background-color: $normal-2;
  height: 100vh;
}
