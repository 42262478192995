
.ccm-modal--add-sequence-rule {
  width: 800px !important;
}

.add-sequence-rule {
  &__form {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 32px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }
}
