@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'ccm-tag';

.#{$name} {
  display: inline-flex;
  align-items: center;
  padding: 2px 12px;
  width: fit-content;
  margin-right: 0;
  @include description-regular();
  color: $neutral5;
  background-color: $neutral1;
  border: none;
  border-radius: 2px;

  .ccm-svg-inline {
    margin-right: 8px;
  }

  &--red {
    color: $red3;
    background-color: $red1;

    &.#{$name}--filled {
      color: $neutral0;
      background-color: $red3;
    }
  }

  &--orange {
    color: $orange3;
    background-color: $orange1;

    &.#{$name}--filled {
      color: $neutral0;
      background-color: $orange3;
    }
  }

  &--blue {
    color: $blue3;
    background-color: $blue1;

    &.#{$name}--filled {
      color: $neutral0;
      background-color: $blue3;
    }
  }

  &--green {
    color: $green3;
    background-color: $green1;

    &.#{$name}--filled {
      color: $neutral0;
      background-color: $green3;
    }
  }

  &--black {
    color: $neutral5;
    background-color: $neutral1;

    &.#{$name}--filled {
      color: $neutral0;
      background-color: $neutral5;
    }
  }
}
