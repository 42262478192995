@import "src/styles/variables";
@import "src/styles/mixins";

.drawer-select-user {
  .ant-drawer-body {
    padding: 0;
  }
  &-add {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    &__label {
      margin-bottom: 16px;
      @include overline($neutral5);
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
  }

  .select-user-single {
    .ccm-radio {
      display: flex;
      flex-direction: column;

      .ant-radio-wrapper {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        margin-right: 0;
        padding: 0 16px 0 8px;
        height: 68px;
        border: 1px solid transparent;

        .ant-radio {
          top: 0;
        }

        &:hover {
          border-color: $neutral1;
          border-radius: 8px;
        }

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        span.ant-radio + * {
          padding: 0;
        }
      }

      .ant-radio-wrapper::after {
        display: none;
      }
    }
  }

  .select-user-multiple {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
    }
    .ant-checkbox-group-item {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      margin-right: 0;
      padding: 7px 16px 7px 8px;
      border: 1px solid transparent;

      &:hover {
        border-color: $neutral1;
        border-radius: 8px;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    .ant-checkbox-wrapper:after {
      display: none;
    }
  }

  &__wrap {
    position: relative;
    display: flex;
    height: 100%;
  }

  &__base {
    padding: 18px 32px 22px 32px;
  }

  &__base,
  &__gantt {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 50%;
  }

  &__gantt {
    padding: 18px 0 22px 32px;
    border-left: 1px solid $neutral1;
  }

  .skeleton-select-user {
    display: flex;
    flex-direction: column;

    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 32px;

      .ant-skeleton-content {
        .ant-skeleton-title {
          margin-top: 0;
        }
        .ant-skeleton-paragraph {
          margin-top: 0 !important;
        }
      }
    }
  }
}
