.info-drawer {
  .ant-drawer-header {
    border: none;
  }
  .ant-drawer-content-wrapper {
    box-shadow: -12px 0px 48px 16px rgba(0, 0, 0, 0.03), -9px 0px 28px rgba(0, 0, 0, 0.05), -6px 0px 16px -8px rgba(0, 0, 0, 0.08) !important;
  }
  .ant-drawer-body {
    padding: 8px 32px 24px 32px;
  }
}
