@import "src/styles/variables";
@import "src/styles/mixins";

.reset-password-page {

  &__title {
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
    color: $neutral5;
  }

  &__title-items {
    display: flex;
    align-items: center;
  }

  &__form {
    width: 400px;
  }

  &__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .text-field--email {
    margin-bottom: 24px;
  }

  .button-login {
    width: 100%;
  }

  & .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
}