@import "src/styles/variables";
@import "src/styles/mixins";

.page-tasks {
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px 32px;
    height: 100%;
  }

  .ccm-table-project-tasks {
    .ant-table-thead {
      position: sticky;
      top: 57px;
      z-index: 3;
    }
  }

  .loading-overlay {
    position: fixed;
    z-index: 9999;
  }

  .skeleton-tasks-header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $neutral1;

    .ant-skeleton-title {
      margin-bottom: 0;
    }

    .skeleton-filter__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 112px;
      padding: 0 32px;

      .ant-skeleton-content .ant-skeleton-title {
        height: 24px;
      }
    }

    .skeleton-filter__bottom {
      display: flex;
      align-items: center;
      height: 56px;
      padding: 0 32px;
      .ant-skeleton-content .ant-skeleton-title {
        height: 24px;
      }
    }
  }

  .skeleton-tasks-table {
    display: flex;
    flex-direction: column;
    padding: 0 32px;

    .skeleton-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 56px;

      .ant-skeleton-title {
        margin: 0 !important;
      }

      .ant-skeleton {
        &:not(:last-child) {
          margin-right: 16px;
        }
      }

      &:first-child {
        .ant-skeleton-title {
          height: 24px;
        }
      }
    }

  }
}
