@import "src/styles/variables";
@import "src/styles/mixins";

.course-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px $space-5;
  @include overline($neutral3);
  text-transform: uppercase;

  &__transparent {
    background: transparent;
    border: 1px solid transparent;
  }

  &__avatars {
    display: flex;
    height: 32px;
    min-width: 120px;
    align-items: center;
    padding-left: 18px;
  }

  &__period {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    width: 170px;
    min-width: 170px;
    margin-right: 24px;
    padding-left: 16px;
  }

  &__tasks {
    display: inline-flex;
    align-items: center;
    width: 160px;
    min-width: 160px;
    margin-right: 16px;
    padding-left: 16px;
  }

  &__deadline {
    display: inline-flex;
    justify-content: flex-start;
    width: 7%;
    min-width: 7%;
    //margin-right: 8px;
    margin-left: 16px;
  }

  &__course-date {
    display: inline-flex;
    justify-content: flex-end;
    width: 110px;
    min-width: 110px;
  }

  &__progress {
    display: flex;
    justify-content: flex-end;
    width: 50px;
    min-width: 50px;
    margin-left: 50px;
    margin-right: 8px;
  }

  &__state {
    display: flex;
    justify-content: flex-end;
    width: 24px;
    min-width: 24px;
    margin-left: 8px;
  }

  &__health {
    display: inline-flex;
    justify-content: flex-end;
    width: 40px;
    min-width: 40px;
  }

  &__period,
  &__tasks,
  &__deadline,
  &__max-delay,
  &__progress,
  &__name,
  &__course-date {
    white-space: nowrap;
  }


  &__name {
    width: 340px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 16px;
  }

  &__max-delay {
    & .plan-fact-tag {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding: 0;
    }
  }

  &__period {
    min-width: 170px;
  }
}
