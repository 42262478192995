@import "src/styles/variables";
@import "src/styles/mixins";

.playback-rate {
  position: relative;

  &-menu {
    position: absolute;
    bottom: 29px;
    width: 50px;


    .ant-menu-item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 32px !important;
      padding: 0 12px !important;
      margin: 0;

      .playback-rate-btn {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        width: 28px;
        font-weight: 700;
        height: 24px;
        padding: 0;
      }
    }
  }

  &__toggle {
    width: auto;
    height: 16px;
    padding: 0 5px !important;
    font-weight: 400;
    font-size: 11px !important;
    line-height: 16px !important;
  }
}
