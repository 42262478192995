@import "src/styles/variables";
@import "src/styles/mixins";

.info-drawer-problems-user {
  display: flex;
  align-items: center;

  .ccm-avatar {
    margin-right: 8px;
  }

  &__roles {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  &__name {
    margin-right: 5px;
    @include body-regular($neutral5);
  }

  &__text {
    @include body-regular($neutral3);
  }
}
