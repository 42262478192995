@import "src/styles/variables";
@import "src/styles/mixins";

.courses-table {

  .ant-spin-nested-loading > div > .ant-spin {
    max-height: calc(100vh - 112px - 54px);
    top: auto;
    bottom: 0;
  }

  //width: 100%;
  //min-height: 100%;
  //
  //.ant-table-thead > tr > th {
  //  background-color: transparent;
  //  border-bottom: 1px solid $normal-4;
  //  @include text($normal-7)
  //}
  //
  //.ant-table-tbody tr:hover {
  //  cursor: pointer;
  //}
  //
  //& .ant-table-cell {
  //  border-bottom: none;
  //}
  //
  //& .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  //  display: none;
  //}
  //
  //&__role-name {
  //  margin-right: 4px;
  //}
  //
  //&__text {
  //  @include body-regular($neutral5);
  //  @include limit-text-rows(1);
  //}
  //
  //tbody {
  //  tr {
  //    &:hover {
  //      cursor: pointer;
  //      box-shadow: 0 2px 0 rgba(0, 0, 0, .02);
  //    }
  //  }
  //}
}
