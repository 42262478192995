@import "src/styles/variables";
@import "src/styles/mixins";

.pipe-flow-custom-node {
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: $white;
  border: 1px solid $neutral3;
  border-radius: 12px;
  min-width: 400px;

  &--final {
    background-color: $neutral0;
  }

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
  }

  &__label {
    margin-bottom: 8px;
    @include text-bold($neutral5);

    &--final {
      margin-bottom: 8px;
      @include text-bold($red-4);
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__rules {
    display: flex;
    flex-direction: column;

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
    }
  }
}
