@import "src/styles/variables";
@import "src/styles/mixins";

.export-element-popup {
  padding-top: 0 !important;
  .ant-popover-inner-content {
    padding: 0;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 72px;
    padding: 0 12px;
  }

  &__footer {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 12px;
    border-top: 1px solid $neutral1;
  }
}

