@import "src/styles/variables";
@import "src/styles/mixins";

.user-elem-btn {
  display: inline-flex;
  align-items: center;
  width: fit-content;
  height: 48px;
  padding: 0 16px 0 12px;
  background-color: $white;
  border: 1px solid $main;
  border-radius: 8px;
  @include overline-bold($neutral5);
  transition: 0.2s;

  &__icon-wrap {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 8px;
    background-color: $main;
    border-radius: 50%;

    .user-elem-btn--l & {
      margin-right: 12px;
      width: 48px;
      height: 48px;
    }

    .user-elem-btn--xl & {
      margin-right: 12px;
      width: 64px;
      height: 64px;
    }
  }

  &:hover {
    transition: 0.2s;
    cursor: pointer;
    border-color: $neutral5;
    color: $neutral3;
  }

  &:disabled {
    cursor: auto;
    background-color: $neutral0;
    border-color: $neutral2;
    color: $neutral3;
  }

  &--l {
    height: 64px;
    padding-right: 24px;
    border-radius: 12px;
  }

  &--xl {
    height: 88px;
    padding-left: 16px;
    padding-right: 24px;
    border-radius: 16px;
  }
}
