@import "src/styles/variables";
@import "src/styles/mixins";

.ccm-label {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__text {
    margin-bottom: 8px;
    @include description-regular($neutral3);
  }
}
