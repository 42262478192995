@import "src/styles/variables";
@import "src/styles/mixins";

.loading-overlay {
  position: absolute;
  z-index: 4;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: $neutral0;
  opacity: 0.9;

  .ccm-spin {
    margin-bottom: 10px;
  }

  &__text {
    @include description-regular($neutral5);
  }
}
