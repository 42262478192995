@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'ccm-table';

.#{$name} {
  height: 100%;

  .ant-spin-nested-loading {
    height: 100%;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 389px;

    img {
      width: 64px;
      height: 40px;
    }
  }

  .ant-table-thead {
    .ant-table-cell {
      padding: 18px 16px;
      background-color: $neutral0;
      border: none;
      @include overline($neutral3);
      text-transform: uppercase;

      &:before {
        display: none !important;
      }
    }
  }

  .ant-table-tbody {
    tr {
      &:hover {
        cursor: pointer;
        position: relative;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        background-color: $neutral0 !important;
        z-index: 2;
        td {
          background-color: $neutral0 !important;
        }
      }
    }


    .ant-table-cell {
      padding-top: 12px;
      padding-bottom: 12px;
      background-color: $neutral0;
      border: none;
      @include body-regular();
    }
  }

  .ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
    margin-top: -40px;
    font-size: 36px;
    left: 49%;
  }
}

