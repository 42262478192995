@import "src/styles/variables";
@import "src/styles/mixins";

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $white;
}

.header {
  display: flex;
  width: 100%;
  height: 64px;
  padding: 0 24px 0 64px;

  &__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
    color: $neutral5;
  }
}

.video {
  width: 100%;
  height: 100%;
  max-width: 952px;
  //max-height: 446px;
  margin-bottom: 16px;
}

.btnClose {
  margin-left: 16px;
  color: $neutral5 !important;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 0 64px 24px 64px;
  overflow-y: auto;
  @include scrollSmall();
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
}

.contentTitle {
  display: inline-flex;
  align-items: center;
  height: 32px;
  @include description-regular($neutral3);
}

.list {
  list-style-type: disc;
  padding-left: 16px;

  &__item {
    @include text();
  }
}

.videoDummy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 240px;
  margin-bottom: 16px;
  background-color: $neutral1;

  &__icon {
    margin-bottom: 6px;
    font-size: 48px;
    color: $normal-5;
  }

  &__text {
    @include text($normal-7);
  }
}

.tasks {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tasksTitle {
  margin-bottom: 8px;
  @include description-regular($neutral3);
}


