@import "src/styles/variables";
@import "src/styles/mixins";

.select-users {
  display: inline-flex;
  flex-direction: column;
  width: fit-content;
  row-gap: 12px;

  &__row {
    display: inline-flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__label {
    padding: 0 20px;
    @include overline($neutral5);
  }

  &__empty {
    @include h5-regular($neutral3);
  }

  .ccm-button {
    width: fit-content;
  }

  &__canEdit {
    &:hover {
      cursor: pointer;
    }
  }
}
