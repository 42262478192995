@import "src/styles/variables";
@import "src/styles/mixins";

.info-drawer-deadlines {
  .ant-timeline-item-head {
    color: $neutral5;
    border-color: $neutral5;
  }

  .ant-timeline-item-content {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 17px;
  }

  &__value {
    margin-top: 3px;
    @include description-regular($neutral5);

    &:hover {
      cursor: pointer;
    }
  }

  &__info {
    @include footnote($neutral3);
  }
}
