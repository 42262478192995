@import "src/styles/variables";
@import "src/styles/mixins";

.user-detail {
  display: inline-flex;
  align-items: center;

  &__name {
    @include body-regular($neutral5);
  }

  .ccm-avatar {
    margin-right: 8px;
  }
}

.user-detail-popup {
  .ant-popover-inner-content {
    padding: 0;
  }
}

.user-detail-content {
  display: flex;
  flex-direction: column;
  min-width: 360px;

  &__top,
  &__bottom {
    display: flex;
    flex-direction: column;
    padding: 12px 16px;

    &--no-padding {
      padding: 0;
    }
  }

  &__bottom {
    &--no-padding {
      padding-top: 12px;
    }
  }

  &__top {
    &--no-padding {
      padding-bottom: 12px;
    }
  }

  &__top {
    border-bottom: 1px solid $neutral1;
  }

  &__user {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    &-name {
      @include h4-regular($neutral5);
    }

    .ccm-avatar {
      margin-right: 8px;
    }
  }

  &__status {
    display: flex;
    align-items: center;

    .status-flat .dot {
      width: 8px;
      height: 8px;
    }

    .status-flat__value {
      font-weight: 700;
      font-size: 13px;
      line-height: 20px;
      text-transform: uppercase;

      &-time {
        @include description-regular($neutral4);
      }
    }
  }
}
