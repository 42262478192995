@import "src/styles/variables";

.separate-task-skeleton {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 0 32px;
    border-bottom: 1px solid $neutral1;
  }

  &__content {
    padding: 0 32px;
  }

  &__images {
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-top: 32px;

    .ant-skeleton-image {
      width: 160px !important;
      height: 160px !important;
    }
  }
}
