@import "src/styles/variables";
@import "src/styles/mixins";

.download-drawer-version {
  width: fit-content;
  padding-left: 0 !important;
  //Temp
  &:hover {
    cursor: auto;
  }

  .anticon {
    color: $neutral2;
    font-size: 16px;
  }

  &__text {
    margin-left: 8px;
    @include overline-bold($neutral2);
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
}
