@import "src/styles/variables";

.ccm-carousel {

  .slick-dots {
    li {
      button {
        background-color: $ccm-blue-6;
        opacity: 0.3;
      }
    }

    .slick-active {
      button {
        background-color: $ccm-blue-6 !important;
        opacity: 1;
      }
    }
  }
}
