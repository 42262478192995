@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'error-card';

.#{$name} {
  display: flex;
  flex-direction: column;
  margin: 0 -24px;
  padding: 8px 24px;
  background-color: $neutral0;
  box-shadow: inset 0px -1px 0px #F7F6F9;

  cursor: pointer;

  &--issue,
  &--selected, {
    background-color: $neutral1;
    cursor: auto;
  }

  &__discuss {
    margin-left: 24px;
  }

  &__head {
    display: flex;
    align-items: center;

    .ccm-tag {
      margin-left: auto;
      margin-right: 0;
    }

    .anticon-box-plot {
      color: $orange-6;
      margin-left: $space-1;
    }
  }

  &__timeline {
    @include body-regular($neutral5);
    margin-right: $space-1;
  }

  &__version {
    @include description-regular($normal-7);
    margin-right: $space-1;
  }

  &__title {
    @include h5-regular($neutral5);
    margin-bottom: $space-2;
  }

  &__info {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .anticon-robot {
    color: $orange-6;
    margin-right: 5px;
  }

  &__time {
    @include footnote($neutral3);
  }

  &__user-name {
    @include footnote($neutral5);
    margin-right: $space-1;
  }

  &__message {
    @include description-regular($neutral5);
  }

  &__count-message {
    margin-left: auto;
    @include description($neutral5);

    .anticon-comment {
      margin-left: 5px;
    }
  }

  &__controls {
    display: flex;
    flex-direction: column;

    .ccm-button--edit {
      margin-left: 10px;
    }

    .ccm-button--all {
      display: inline-flex;
      width: fit-content;
      color: $neutral5;
    }

  }
}
