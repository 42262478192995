@import 'src/styles/variables';
@import 'src/styles/mixins';

.ant-popconfirm {

  .ant-popover-inner {
    padding: 16px;
  }

  .ant-popconfirm-description {
    margin-left: 0;
    @include body-regular($neutral5);
  }

  .ant-popover-message {
    .anticon {
      top: 8px;
    }
  }

  & .ant-btn-dangerous {
    color: $red3;
    background: $white;
    border: 1px solid $red3;
    border-radius: 40px;
    padding: 0 15px;
    height: 31px;

    &:hover,
    &:active {
      border: 1px solid $red2;
      background: $red1;
      color: $red2;
    }
  }
}
