@import "src/styles/variables";
@import "src/styles/mixins";

.tree-edit-section {
  display: flex;
  width: 100%;
  margin-top: 2px;
  padding: 0 16px 16px 0;
  background-color: $neutral0;

  &__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__count {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 24px;
    @include description($normal-7);
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 560px;

    .text-field--title {
      height: 40px !important;
      margin-bottom: $space-3;
    }

    .text-field--description {
      margin-bottom: $space-3;
    }

    textarea {
      resize: none;
    }
  }

  &__buttons {
    margin-top: 16px;
    display: flex;
  }

  &--fake-button-save {
    display: inline-flex;
    height: 24px;
    align-items: center;
    padding: 1px 7px !important;
    background-color: $normal-3 !important;
    border: 1px solid $normal-5 !important;
    color: $normal-6 !important;
  }
}
