@import "src/styles/variables";
@import "src/styles/mixins";

.separate-task-comments {
  display: flex;
  flex-direction: column;
  padding-top: 24px;

  &__label {
    margin-bottom: 16px;
    @include overline($neutral3);
  }
}
