@import "src/styles/variables";
@import "src/styles/mixins";

.add-material-block {
  position: relative;
  display: flex;
  width: 100%;
  padding: $space-5 18px;
  background-color: $normal-2;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 2px;
    height: 100%;
    background-color: $neutral3;
  }

  & a {
    word-break: break-all;
    @include h5-regular($neutral5);
  }
}
