@import "src/styles/variables";
@import "src/styles/mixins";

.slide-preview {
  position: relative;
  max-width: 275px;
  width: 100%;
  height: 212px;

  &:hover {
    cursor: pointer;
  }

  &-upload {
    max-width: 275px;
    width: 100%;
    height: 180px;

    .ant-upload {
      width: 100%;

      .slide-preview {
        height: 180px;
      }
    }
  }


  &__bg {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    height: 180px;
    border: 1px dashed $neutral1;
    border-radius: 2px;

    .ant-progress-line {
      max-width: 100px;
    }
  }

  &__text {
    @include text();
    margin-bottom: 18px;
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &__load {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: $neutral0;
  }

  &-controls {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    height: 95%;
    z-index: 2;
    background: $normal-12;
    opacity: 0.6;

    &__row {
      position: relative;
      display: flex;
      align-items: center;

      .upload-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        margin: 0 $space-1;
        padding-top: 2px;

        &:hover {
          cursor: pointer;
        }

        .ant-upload {
          display: flex;
          align-items: center;
          width: 16px;
          height: 16px;

          & svg {
            width: 16px;
            height: 16px;
          }
        }


        .anticon {
          width: 16px;
          height: 16px;
          color: $normal-1;
        }
      }

      .ccm-button--icon {
        width: 16px;
        height: 16px;
        margin: 0 $space-1;
        color: $neutral1;

        &:disabled {
          background-color: transparent;
        }
      }

      .ccm-button--icon[disabled]:hover {
        background-color: transparent;
        color: $neutral1;
      }
    }
  }

  &__btn {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 2px;
    background-color: $neutral0;

    .ant-progress-line {
      max-width: 100px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
