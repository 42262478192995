@import "src/styles/variables";
@import "src/styles/mixins";

.users-table {
  width: 100%;

  .ant-table-thead {
    th:first-child {
      padding-left: 32px;
    }
  }

  .ant-table-tbody {
    td:first-child {
      padding-left: 32px;
    }

    td {
      height: 32px;
      max-height: 32px;
      padding-top: 12px !important;
      padding-bottom: 12px !important;
    }

    .ccm-avatar {
      margin-right: 12px;
    }
    .status-flat {
      padding: 0;
    }
  }

}
