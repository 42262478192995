@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'ex-item';

.#{$name} {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 64px;
  background-color: $neutral0;
  border: 1px solid  $neutral0;
  border-radius: 6px;

  &:hover {
    position: relative;
    z-index: 2;
    cursor: pointer;
    border-color: $neutral3;
  }

  &--edit {
    &:hover {
      border-color: $neutral0;
    }
  }

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  &__icon-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 10px;

    &:after {
      z-index: 3;
      position: absolute;
      content: '';
      top: -26px;
      width: 1px;
      height: 16px;
      background-color: $neutral2;
    }
  }

  &__name {
    @include body-regular($neutral5);
    width: 230px;
    margin-right: 18px;

    @media screen and (min-width: 1900px) {
      width: 300px;
    }
  }

  &__iteration {
    width: 48px;
    margin-right: 32px;
    &-value {
      margin-left: 4px;
      @include body-regular($neutral5);
    }
  }

  &__user-name {
    width: 110px;
    height: 32px;
    margin-right: 32px;

    @media screen and (min-width: 1900px) {
      margin-right: 80px;
    }
  }

  &__deadline {
    display: inline-flex;
    align-items: center;
    width: 170px;
    margin-right: 32px;

    @media screen and (min-width: 1900px) {
      margin-right: 80px;
    }

    .#{name}--edit & {
      width: 80px;
    }

    .ccm-popover-history {
      margin-left: 2px;
    }

    &-second {
      margin-right: 40px !important;
    }
  }

  &__status {

    &-change {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      width: 90px;
      margin-right: 80px;

      &-value {
        width: 58px;
        text-align: end;
        @include description-regular();
      }

      &-empty {
        width: 58px;
      }
    }
  }

  &__task {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }
}
