@import "src/styles/variables";
@import "src/styles/mixins";

.separate-task-prod-date {
  display: flex;
  align-items: center;
  gap: 24px;

  &__column {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__label {
    margin-bottom: 12px;
    @include description-regular($neutral7);
  }

  &__value {
    @include body-regular($neutral5);
  }
}
