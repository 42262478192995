@import "src/styles/variables";
@import "src/styles/mixins";

.info-drawer-content-progress {
  display: flex;
  flex-direction: column;

  &__label {
    margin-bottom: $space-2;
    @include description-regular($neutral3);
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $space-1;
  }

  &__percent {
    @include body-regular($neutral5);
  }

  &__completed {
    @include description-regular($neutral3);
  }

  .ant-progress-inner {
    height: 6px !important;
    .ant-progress-bg {
      height: 6px !important;
      background-color: $neutral5;
    }
  }

  .ant-progress-status-success .ant-progress-bg {
    background-color: $green-6;
  }
}
