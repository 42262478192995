@import "src/styles/variables";
@import "src/styles/mixins";

.ccm-popover-history {

  .ccm-button .ant-btn-link, .ccm-button.ant-btn-link {
    color: $neutral5;
  }

  &__wrapper {
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 100%;
    max-height: 80vh;

    .ccm-timeline {
      overflow: auto;
      padding-right: 8px;
      padding-top: 8px;
      @include scrollSmall();
    }
  }

  .ccm-popover {
    //margin-left: $space-1;

    & .ant-popover-title {
      height: 34px;
      display: inline-flex;
      align-items: center;
      @include h5-regular($neutral5);
      box-shadow: inset 0px -1px 0px #F7F6F9;
    }

    .ant-popover-inner {
      width: 360px;

      &-content {
        overflow-y: auto;
        height: 220px;
        @include scrollSmall();
      }
    }


    .anticon-loading {
      font-size: 60px;
      color: $neutral5
    }
  }
}

