@import "src/styles/variables";
@import "src/styles/mixins";

.plan-loading-overlay {
  position: fixed;
  top: 169px;
  height: calc(100vh - 169px);
}

.project-page-plan {
  max-width: 100%;
  padding: 0 8px 24px 8px;

  &--footer {
    padding-bottom: 90px;
  }

  &-empty {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    max-width: 1080px;
    margin: 56px  auto  auto;

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 60vh;
    }

    &__title {
      margin-bottom: $space-1;
      @include h5-regular($normal-9);
    }

    &__text {
      margin-bottom: 21px;
      @include text($normal-7);
    }

    &__link {
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
    }
  }
}
