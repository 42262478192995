@import "src/styles/variables";
@import "src/styles/mixins";

.dashboard-block-header {
  &__wrapper {
    display: inline-flex;
    align-items: center;

    .ccm-badge {
      margin: 5px 2px auto 2px;
    }

    .anticon-arrow-right {
      font-size: 20px;
    }
  }

  &__link {
    display: inline-flex;
    height: 56px;
  }

  &__title {
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
    color: $neutral5;
  }


}
