@import "src/styles/variables";
@import "src/styles/mixins";

.deadline-indicator {
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
  border-radius: 2px;

  &--warning {
    background-color: $orange-1;
  }

  &--error {
    background-color: $red-1;
  }

  &__date {
    margin-right: $space-2;
    @include text($normal-12);
    white-space: nowrap;

    .deadline-indicator--warning & {
      color: $orange-6;
    }

    .deadline-indicator--error & {
      color: $red-6;
    }
  }

  &__delay {
    @include text($normal-8);
  }
}
