@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'error-constructor';

.#{$name} {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 64px;
  padding: $space-4 $space-5;
  background-color: $normal-4;

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: $space-3;

    .ant-select-selection-item {
      white-space: pre-wrap;
    }

    .#{$name}--small & {
      flex-wrap: wrap;
    }
  }

  &--small {
    .ccm-select {
      margin-bottom: $space-3;
      margin-right: 0;
    }
  }

  .ccm-time-picker {
    width: 102px;
    min-width: 102px;
  }

  .ccm-checkbox--icon {
    .anticon {
      color: $neutral5;
    }
  }

  &__wrap-btn {
    padding: 6px 12px;
    margin: 0 auto;
    height: 32px;
    background-color: $neutral1;
    border-radius: 40px;
    color: $neutral2;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1em;

    &:hover {
      cursor: not-allowed;
    }
  }

  .add-error {
    width: fit-content;
    margin: 0 auto;
  }

  .ccm-select.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .text-field.text-field--no-form,
  .ccm-time-picker.ant-picker {
    background-color: $neutral0;
  }
}
