@import "src/styles/variables";
@import "src/styles/mixins";

.dashboard-course-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px $space-5;
  background: $white;
  margin-bottom: 2px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid $white;

  &__transparent {
    background: transparent;
    border: 1px solid transparent;
  }

  &__stage {
    color: $neutral3;
  }

  .ccm-svg-inline {
    svg {
      path {
        fill: $neutral3;
      }
    }
  }

  &:hover {
    border: 1px solid #848484;
  }

  &__avatars {
    height: 32px;
    min-width: 120px;
  }

  &__period {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    width: 130px;
    min-width: 130px;
    margin-right: 24px;
  }

  &__tasks {
    display: inline-flex;
    align-items: center;
    width: 160px;
    min-width: 160px;
    margin-right: 16px;
  }

  &__deadline {
    display: inline-flex;
    justify-content: flex-start;
    width: 7%;
    min-width: 7%;
    margin-right: 8px;

    & .plan-fact-tag--flat {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__course-date {
    display: inline-flex;
    justify-content: flex-end;
    width: 100px;
    min-width: 100px
  }

  &__progress {
    display: flex;
    justify-content: flex-end;
    width: 50px;
    min-width: 50px;
    margin-left: 50px;
    margin-right: 8px;
  }

  &__state {
    display: flex;
    justify-content: flex-end;
    width: 24px;
    min-width: 24px;
    margin-left: 8px;
  }

  &__health {
    display: inline-flex;
    justify-content: flex-end;
    width: 40px;
    min-width: 40px;
  }

  &__period,
  &__tasks,
  &__deadline,
  &__max-delay,
  &__progress,
  &__name,
  &__course-date {
    @include body-regular($neutral5);
    white-space: nowrap
  }


  &__name {
    width: 340px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__max-delay {
    & .plan-fact-tag {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding: 0;
    }
  }

  &__period {
    min-width: 170px;
  }
}
