@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'ccm-pagination';

.#{$name} {
  user-select: none;

  .ant-pagination-prev,
  .ant-pagination-next {
    border: 1px solid $neutral1;
    font-size: 12px;

    &:hover {
      border-color: $neutral3;
    }
  }

  .ant-pagination-item {
    user-select: none;
    @include body-regular($neutral4);
    line-height: 30px;
    border-color: $neutral1;
    border-radius: 4px;
    background-color: transparent;

    &-active {
      background-color: $white;
      border-color: $neutral2 ;
    }

    &:hover {
      border-color: $neutral3;
    }
  }

  .ant-pagination-jump-next {
    .ant-pagination-item-ellipsis {
      color: $neutral3;
    }
  }

  .ant-pagination-disabled {
    background-color: $neutral1;
    border-color: $neutral1;
    color: $neutral2;
  }

  &.ant-pagination-mini {
    .ant-pagination-item {
      font-size: 14px;
      line-height: 22px;
      border-radius: 2px;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      border: none;
    }

    .ant-pagination-disabled {
      background-color: transparent;
      border: none;
      color: $neutral2;
    }
  }
}

