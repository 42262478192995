@import "src/styles/variables";
@import "src/styles/mixins";

.participants-block {
  display: flex;
  flex-direction: column;
  align-self: start;
  width: 580px;

  @media screen and (max-width: 1439px) {
    width: 368px;
  }
  @media screen and (max-width: 1900px) {
    width: 420px;
  }

  &__label {
    @include overline($neutral5);
  }

  .participants-block__label {
    margin-bottom: 12px;
    padding: 0 20px;
    @include overline($neutral5);
  }

  .ccm-user-info:not(:last-child) {
    margin-bottom: 8px;
  }
}
