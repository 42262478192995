@import "src/styles/variables";
@import "src/styles/mixins";

.courses-filter {
  &__container {
    position: sticky;
    z-index: 2;
    top: 0;
    display: flex;
    align-items: center;
    min-height: 56px;
    height: 56px;
    padding: 0 32px;
    border-bottom: 1px solid $neutral1;
    background-color: $neutral0;

    .ccm-select--delays,
    .ccm-select--assignees,
    .ccm-select--stage,
    .ccm-select--status,
    .ccm-search {
      max-width: 240px;
    }

    .ccm-select--delays,
    .ccm-select--stage {
      margin: 0 16px;
    }

    .ccm-select--assignees {
      margin-right: 16px;
    }

    .ccm-search {
      .ant-input-search-button {
        width: 48px;
      }
    }

  }

  &__label {
    margin: 0 4px;
    @include description-regular($neutral5);
  }
}
