@import "src/styles/variables";

.popup-card {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  min-height: 164px;
  background-color: $normal-1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
