@import "src/styles/variables";
@import "src/styles/mixins";

.ccm-badge {
  min-width: 20px;
  height: 20px;

  .ant-badge-count {
    min-height: 20px;
    min-width: 20px;
    @include description-regular($neutral0);
    line-height: 14px;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;

    .current {
      display: block;
      height: 14px !important;
      line-height: 14px !important;
    }
  }
  .ant-badge .ant-scroll-number .ant-scroll-number-only {
    height: 14px !important;
  }
  .ant-scroll-number-only {
    height: 14px !important;
  }

  &--light {
    .ant-badge-count {
      box-shadow: none;
      color: $neutral5 !important;
      border: 1px solid $neutral2 !important;
      border-radius: 10px;
    }
  }
}
