@import "src/styles/variables";
@import "src/styles/mixins";

.gantt-head {
  position: relative;
  height: 16px;
  margin-bottom: 58px;

  &__wrap {
    position: absolute;
    display: flex;
    align-items: center;
  }

  &__item {
    width: 120px;
    min-width: 120px;
    @include description14($neutral3);
    text-transform: capitalize;
  }
}
