@import "src/styles/variables";
@import "src/styles/mixins";

.courses-page {

  &__add-course {
    display: inline-flex;
    align-items: center;

    .anticon {
      width: 14px;
      height: 14px;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 8px 0 8px;
    height: 100%;
  }

  .loading-overlay {
    position: fixed;
    z-index: 9999;
  }

  .courses-table {
    .ant-table-thead {
      position: sticky;
      top: 56px;
      z-index: 3;
    }
  }

}

.skeleton-course-header {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $neutral1;


  .skeleton-filter__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 112px;
    padding: 0 32px;

    .ant-skeleton-content .ant-skeleton-title {
      height: 24px;
    }
  }

  .skeleton-filter__bottom {
    display: flex;
    height: 64px;
    padding: 0 32px;

    .ant-skeleton-content .ant-skeleton-title {
      height: 16px;
    }
  }
}

.skeleton-course-table {
  display: flex;
  flex-direction: column;
  padding: 0 32px;

  .skeleton-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;

    .ant-skeleton-title {
      margin: 0 !important;
    }

    .ant-skeleton {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    &:first-child {
      .ant-skeleton-title {
        height: 24px;
      }
    }
  }

}
