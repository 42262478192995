.task-page-header {
  grid-area: header;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 32px 8px 64px;
  //height: 48px;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
  }

  &__version {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
  }

  &__buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
