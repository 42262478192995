
.course-info-pop {
  min-width: 360px;

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
  }
}
