@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'status-label';

.#{$name} {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  height: 22px;

  &__value {
    @include description()
  }

  &--blocked {
    background-color: $normal-2;
    border: 1px solid $normal-5;

    .#{$name}__value {
      color: rgba(0, 0, 0, 0.85);
    }
  }
  &--block,
  &--declined,
  &--blocked {
    background-color: $red-1;
    border: 1px solid $red-3;

    .#{$name}__value {
      color: $red-6;
    }
  }
  &--in_progress {
    background-color: $normal-2;
    border: 1px solid $normal-5;

    .#{$name}__value {
      color: rgba(0, 0, 0, 0.85);
    }
  }

  &--done,
  &--invited {
    background-color: $ccm-blue-1;
    border: 1px solid $ccm-blue-3;

    .#{$name}__value {
      color: $ccm-blue-6;
    }
  }

  &--edits {
    background-color: $orange-1;
    border: 1px solid $orange-3;

    .#{$name}__value {
      color: $orange-5;
    }
  }

  &--approved,
  &--registered {
    background-color: $green-1;
    border: 1px solid $green-3;

    .#{$name}__value {
      color: $green-6;
    }
  }

  &--rounded {
    border-radius: 24px;
  }
}
