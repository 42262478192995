@import "src/styles/variables";
@import "src/styles/mixins";

.ant-notification {
  &-notice {
    &-message {
      font-weight: 400 !important;
      font-size: 20px !important;
      line-height: 28px !important;
      color: $neutral5 !important;
      display: flex!important;
      align-items: center!important;
      margin-bottom: 0!important;
    }
    &-description {
      margin-top: $space-2 !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      color: $neutral3 !important;
    }
  }
}

