@import "src/styles/variables";

.ccm-modal--wrapper.edit-list {
  font-family: 'PT Root UI', sans-serif;
  min-width: 520px;



  .edit-list-form {
    display: flex;
    flex-direction: column;
  }

  .ccm-checkbox {
    align-items: center;
    margin-bottom: 12px;
    margin-inline-start: 0;
  }
}
