@import "src/styles/variables";
@import "src/styles/mixins";

.ccm-dropdown-menu {
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 0;
  background-color: $white;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  .ant-menu-item {
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin: 0 !important;
    padding: 0 12px;
    height: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;

    &:hover {
      background-color: $neutral1;
    }

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius:0 0 8px 8px;
    }
  }

  &-item__icon {
    margin-right: 8px;
  }

  &__value-text {
    display: inline-flex;
    width: 100%;

    &.danger {
      color: #ff4d4f;
    }
  }
}

.ant-dropdown-menu-title-content {
  font-size: 14px;
  line-height: 20px;
  color: $neutral5;
  text-transform: none;
}

.ant-dropdown-menu-item {
  .ccm-checkbox {
    margin-right: $space-2;
  }
}

.ccm-dropdown-menu--checkbox {
  .ant-dropdown-menu-item {
    padding: 0;

    .ccm-checkbox {
      margin: 0;
      padding: 5px 12px;
      font-size: 12px;
    }
  }
}

