@import "src/styles/variables";

.users-toolbar {
  position: sticky;
  top: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  padding: 12px 32px;
  border-bottom: 1px solid $neutral1;
  background-color: $neutral0;

  .ccm-select {
    width: 240px;
    margin-right: 12px;
  }

  .ccm-search {
    width: 264px;
  }
}
