@import "src/styles/variables";
@import "src/styles/mixins";

.info-drawer-problem-view {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;

  .ccm-svg-inline {
    margin-right: 18px;
  }

  &__text {
    @include description-regular($neutral3);
  }

  &__days,
  &__tasks {
    justify-self: flex-end;
    margin-right: 5px;
    @include body-regular($neutral5);
  }

  &__tasks {
    width: 90px;
    text-align: end;

    &:hover {
      cursor: pointer;
    }

    &-no-user {
      &:hover {
        cursor: auto;
      }
    }
  }
}
