@import "src/styles/variables";

.auth-page {
  .ccm-button--icon {
    width: 21px !important;
    min-width: 21px !important;
    height: 21px !important;
    padding: 0 !important;
    margin-right: 9px;
    background-color: transparent !important;
    border: none !important;

    & svg {
      width: 21px;
      height: 21px;
      color: $neutral5;
    }
  }
}