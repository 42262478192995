@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'ccm-title';

.#{$name} {
  margin: 0;

  &--h1 {
    @include h1-medium();
  }

  &--h2 {
    @include h2-medium();
  }

  &--h3 {
    @include h3-medium();
  }

  &--h4 {
    @include h4-medium();
  }

  &--h5 {
    @include h5-medium();
  }

  &--h6 {
    @include body-regular();
    font-weight: 500;
  }
}

