@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'ccm-modal--assigners-users';

.#{$name} {
  min-width: 1296px;

  & .participants {
    padding: 0 32px 32px 32px;
    overflow-y: auto;
    @include scrollSmall();
  }

  & .assigners-title {
    @include h5-regular($neutral5);
    margin-bottom: 12px;
  }
}

