
.pipeline {
  display: flex;
  flex-direction: column;

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 32px;
  }
}
