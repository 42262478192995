@import "src/styles/variables";
@import "src/styles/mixins";

.task-universal {

  &__view {
    grid-area: view;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 64px;
    background-color: $normal-1;
    border-radius: 20px 20px 0 0;
  }

  &__tabs {
    grid-area: tabs;
    background-color: $neutral0;
    box-shadow: inset 1px 0 0 #F7F6F9;
    height: calc(100vh - 60px);

    & .ant-tabs-nav,
    & .ant-tabs-content-holder {
      padding: 0;
    }

    .ant-tabs-content-holder {
      padding-bottom: 16px;
    }

    & .ant-tabs-nav {
      padding: 0 $space-5;
    }

    .ant-tabs-content {
      height: 100%;
      padding: 0 $space-5;
      overflow-y: auto !important;
      @include scrollSmall();
    }

    & .editor {
      .cdx-input.image-tool__caption {
        display: none;
      }
    }
  }

}
