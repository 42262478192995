@import "src/styles/variables";

.error-mark {
  position: absolute;
  top: -2px;
  z-index: 2;
  width: 10px;
  height: 10px;
  border: 2px solid $red-6;
  border-radius: 50%;
  cursor: pointer;
  background-color: $normal-1;

  &__long {
    border-radius: 100px;
  }

  &--active {
    background-color: $red-6;
  }
}
