@import "src/styles/variables";
@import "src/styles/mixins";

.modal-preview-task {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 48px;

  &:hover {
    cursor: pointer;
  }

  &__art-type {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    width: 240px;
  }

  &__user-name {
    width: 160px;
    overflow: hidden;
    font-size: 13px;
    line-height: 22px;
    color: $neutral7;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__iteration {
    width: 50px;
    text-align: start;
    font-size: 13px;
    line-height: 22px;
    color: $neutral7;
  }

  &__indicator {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    width: 135px;
  }

  &__status {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    width: 150px;

    .status-flat {
      padding: 0;
    }
  }

  &__change-status {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    width: 110px;

    &-value {
      margin-right: $space-2;
      @include text($normal-7);
    }
    &-empty {
      margin-right: 40px;
      @include text($normal-7);
    }
  }
}
