@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'video-progress';

.#{$name} {
  position: relative;
  display: flex;
  width: 100%;
  margin-left: 15px;

  &__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 10px;
    overflow-x: hidden;
  }

  .ant-slider {
    width: 100%;
    height: 6px;
    padding: 0;
    margin: 0;


    &-rail {
      height: 6px;
      border-radius: 100px;
      background-color: $neutral2;
    }

    &-dot {
      width: 10px;
      height: 10px;
      border-color: $red-5;
    }

    &-track {
      height: 6px;
      border-radius: 100px;
      background-color: $neutral3;
    }

    &-handle {
      display: none;
    }

    &:hover {
      .ant-slider-track {
        height: 6px;
        border-radius: 100px;
        //background-color: $neutral2;
        transition-duration: 200ms;
        transition-property: width;
      }
    }
  }
}
