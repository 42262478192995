@import "src/styles/variables";
@import "src/styles/mixins";

.instruction {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - 56px - 56px - 48px);
  overflow: hidden;

  &__wrapper {
    overflow: auto;
    @include scrollSmall();
  }

  & h4 {
    margin-block-start: 0;
    margin-bottom: $space-4;
    @include h4-regular($neutral5);
  }

  & h5 {
    margin-bottom: $space-1;
    @include h5-regular($neutral5);
  }

  & p {
    margin-bottom: $space-4;
    @include text($neutral5);
  }

  & ul {
    margin-bottom: $space-4;
    padding-left: 20px;
    list-style-type: disc;
  }

  & li {
    @include text($neutral5);
  }
}
