@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/variables";

$name: 'text-field';

.#{$name} {

  input[data-autocompleted] {
    background-color: $neutral1 !important;
  }

  &.text-field--no-form,
  .ant-input,
  .ant-input-password {
    padding: 6px 12px 6px 8px;
    font-size: 12px;
    line-height: 18px;
    background-color: $neutral1;
    border-color: $neutral1;
    border-radius: 4px;

    &-status-warning:not(.ant-input-disabled):not(.ant-input-borderless) {
      background-color: $orange1;
      border-color: $orange1;
      color: $orange3;

      &:hover,
      &:focus {
        box-shadow: none;
      }
    }

    &-status-error:not(.ant-input-disabled):not(.ant-input-borderless) {
      background-color: $red1;
      border-color: $red1;
      color: $red3;

      &:hover,
      &:focus {
        box-shadow: none;
      }
    }

    &::placeholder {
      color: $neutral3;
    }

    &:hover {
      border-color: $neutral3;
      box-shadow: none;
    }

    &:focus {
      background-color: $white;
      border-color: $neutral1;
      box-shadow: none;
    }

    &-lg {
      padding: 9px 12px;
      font-size: 14px;
      line-height: 20px;
      border-radius: 6px;
    }

    &-disabled {
      background-color: $neutral1 !important;
      border-color: $neutral1 !important;
      color: $neutral2 !important;

      &::placeholder {
        color: $neutral2 !important;
      }
    }
  }

  .anticon-eye {
    color: $neutral5;
  }

  &.ant-input-affix-wrapper,
  & .ant-input-affix-wrapper {
    background-color: $neutral1;
    border-color: $neutral1;

    &-focused {
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
    }

    &-focused {
      background-color: $white;
    }
  }

  &--xl {
    &.text-field--no-form,
    .ant-input,
    .ant-input-password,
    .ant-input-number {
      padding: 11px 12px 11px 16px;
      font-size: 16px;
      line-height: 24px;
      border-radius: 8px;
    }
    &.text-field--no-form {
      height: 48px !important;
    }
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px $neutral1 inset !important;
  }
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px $white inset !important;
    transition: all 0.3s;
  }

  input::selection {
    background-color: $neutral3;
  }

  .ant-form-item-label > label {
    @include description-regular($neutral3);
  }

  .ant-form-item-explain-error {
    @include description-regular($red3);
  }

  & input#password.ant-input {
    &:focus {
      background-color: $white
    }
  }

  & .ant-input-password {
    &:focus {
      background-color: $white
    }

    & .ant-input:focus,
    & .ant-input:active {
      background-color: $white !important;
    }
  }
}
