@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'ccm-switch';

.#{$name} {
  display: flex;
  align-items: center;

  &__label {
    @include text14($normal-9);
    margin-left: $space-2;

    .#{$name}--small & {
      @include description($normal-9);
    }

    .#{$name}--disabled & {
      color: $black25;
    }
  }

  .ant-switch {
    background-color: $neutral3;

    &-disabled {
      background-color: $neutral1;
    }
  }


  .ant-switch-checked {
    background-color: $neutral5;
  }
}
