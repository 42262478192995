@import "src/styles/variables";
@import "src/styles/mixins";

.participants {
  display: flex;
  flex-direction: column;
  width: 100%;

  .user-element {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    column-gap: 24px;

    &-wrap {
      flex-wrap: wrap;
      row-gap: 24px;
    }

    &-authors {
      .participants-group__content {
        display: flex;
        flex-direction: row;
        column-gap: 24px;
      }
    }
  }

  &__title {
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
    color: $neutral5;
  }
}
