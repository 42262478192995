@import "src/styles/variables";
@import "src/styles/mixins";

.task-page {
  &.task-draft-editing,
  &.task-universal,
  &.task-final-presentation,
  &.task-text {
    display: grid;
    width: 100%;
    grid-template-areas:
          "header header"
          "view tabs";
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 560px;

    &__panel--closed {
      grid-template-columns: auto 64px !important;

      .ant-tabs-nav {
        flex-direction: row-reverse;
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }

  background-color: $neutral0;
}
