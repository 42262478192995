@import "src/styles/variables";
@import "src/styles/mixins";

.global-tasks-collapse {
  display: flex;
  flex-direction: column;
  padding: 12px 20px 12px 0;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    &__title {
      margin-left: 80px;
      @include h5-regular($neutral5);
    }

    &__extra {
      display: inline-flex;
      align-items: center;
    }

    &__progress {
      display: inline-flex;
      align-items: center;
      margin-right: 16px;

      &-value {
        @include h5-regular($neutral5);
      }
      &-text {
        margin-left: 2px;
        @include footnote($neutral3);
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    height: 0;
    overflow: hidden;

    &--opened {
      height: unset;
    }
  }
}

.global-task-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  padding-left: 150px;
  border: 1px solid transparent;
  border-radius: 6px;

  &:hover {
    cursor: pointer;
    border-color: $neutral3;
  }

  &__art-type {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 16px;

    .ccm-tooltip {
      width: 20px;
      height: 20px;
    }
  }

  &__title {
    min-width: 230px;
    max-width: 230px;
    width: 100%;
    margin-right: 68px;
    @include body-regular($neutral5);

    @media screen and (min-width: 1900px) {
      min-width: 300px;
      max-width: 300px;
    }
  }

  &__iteration {
    display: inline-flex;
    align-items: center;
    width: 48px;
    margin-right: 32px;

    @media screen and (min-width: 1900px) {
      margin-right: 80px;
    }

    &-value {
      margin-left: 4px;
      @include body-regular($neutral5);
    }
  }

  &__users {
    width: 110px;
    margin-right: 16px;

    @media screen and (min-width: 1900px) {
      margin-right: 80px;
    }
  }

  &__deadline {
    display: inline-flex;
    align-items: center;
    width: 170px;
    margin-right: 16px;

    &-second {
      margin-right: 24px;
    }

    @media screen and (min-width: 1900px) {
      margin-right: 80px;
    }
  }

  &__status-change {
    display: inline-flex;
    align-items: center;
    margin-right: 16px;
    width: 75px;
    min-width: 75px;

    @media screen and (min-width: 1900px) {
      margin-right: 80px;
    }

    &-value {
      margin-left: 2px;
      text-align: end;
      @include description-regular($neutral5);
    }
  }
}
