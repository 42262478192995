.comment-pane {
  display: flex;
  flex-direction: column;

  .text-field {
    height: auto !important;
    min-height: 40px;
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-top: 16px;
  }
}
