@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px dashed $normal-4;
}

.text {
  @include text();
  margin-bottom: 18px;
}
