@import "src/styles/variables";
@import "src/styles/mixins";

.header-small {
  position: sticky;
  z-index: 2;
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 0 32px;
  border-bottom: 1px solid $neutral1;
  background-color: $neutral0;

  &__title {
    width: 80%;
    @include h4-regular($neutral5);
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}
