@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'video-control-panel';

.#{$name} {
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 12px 0 16px;
  background-color: $normal-4;

  &__btn-play {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    padding: 0;
    width: 20px !important;
    min-width: 20px !important;
    height: 20px !important;
  }
}
