
.ms-pipeline-edit {
  display: flex;
  flex-direction: column;

  &__top {
    display: flex;
    align-items: center;
    column-gap: 32px;
    margin-bottom: 16px;

    .ccm-select {
      width: 250px;
    }
  }
}
