@import 'src/styles/variables';
@import 'src/styles/mixins';

$name: 'ccm-button';
.#{$name} {

  .anticon {
    transform: scale(1) !important;
  }

  & .ant-btn,
  &.ant-btn {
    border-radius: 40px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    font-family: 'PT Root UI', sans-serif;

    &-primary {
      border: 1px solid $neutral5;
      color: $neutral0;

      &:hover,
      &:active {
        border: 1px solid $neutral3;
        background: $neutral3;
      }

      &.ant-btn-loading {
        border: 1px solid $neutral3;
        background: $neutral3;
      }

      &:disabled {
        background: $neutral1;
        border: 1px solid $neutral1;
        color: $neutral2;

        &:hover,
        &:active {
          border: 1px solid $neutral1;
          background: $neutral1;
          color: $neutral2;
        }
      }

      &.ant-btn-dangerous {
        border: 1px solid $red3;

        &:hover,
        &:active {
          border: 1px solid $red2;
          background: $red2;
          color: $neutral0
        }

        &:disabled {
          border: 1px solid $neutral1;
          background: $neutral1;
          color: $neutral2;
        }
      }
    }

    &-default {
      border: 1px solid $neutral2;
      color: $neutral5;

      &:hover,
      &:active {
        border: 1px solid $neutral3;
        background: $white;
        color: $neutral3;
      }

      &.ant-btn-loading {
        color: $neutral5;
        border: 1px solid $neutral3;
      }

      &.ant-btn-dangerous {
        color: $red3;
        background: $white;
        border: 1px solid $red3;

        &:hover,
        &:active {
          border: 1px solid $red2;
          background: $red1;
          color: $red2;
        }
      }

      &:disabled {
        background: $neutral1;
        color: $neutral2;
        border: 1px solid $neutral1;

        &:hover,
        &:active {
          border: 1px solid $neutral1;
          background: $neutral1;
          color: $neutral2;
        }
      }
    }

    &-text {
      color: $neutral5;

      &:hover,
      &:active {
        border: 1px solid $white;
        background: $white;
        color: $neutral3;
      }

      &:focus {
        color: $neutral5;
        background: transparent;
        border: 1px solid transparent;
      }

      &.ant-btn-dangerous {
        color: $red3;

        &:hover,
        &:active {
          border: 1px solid transparent;
          background: $red1;
          color: $red2;
        }

        &:focus {
          color: $red2;
          background: transparent;
          border: 1px solid transparent;
        }

        &:disabled {
          background: transparent;
          color: $neutral2;
          border: 1px solid transparent;
        }
      }

      &:disabled {
        background: transparent;
        color: $neutral2;
        border: 1px solid transparent;
      }
    }

    &-link {
      color: $neutral5;
      background: transparent;

      &.ant-btn-loading {
        color: $neutral3;
        border: transparent;
        background: transparent;
      }

      &.ant-btn-dangerous {
        color: $red3;

        &:hover,
        &:active {
          border: 1px solid transparent;
          background: transparent;
          color: $red2;
        }

        &:focus {
          color: $red2;
          background: transparent;
          border: 1px solid transparent;
        }

        &:disabled {
          background: transparent;
          color: $neutral2;
          border: 1px solid transparent;
        }
      }

      &:disabled {
        background: transparent;
        color: $neutral2;
        border: 1px solid transparent;
      }
    }

    &.ant-btn-dangerous {
      background-color: $red3;
      color: $neutral0;
      border-color: $red3;

      &:hover {
        background-color: $red2;
        color: $neutral0;
        border-color: $red2;
      }
    }
  }

  &--small.ant-btn-icon-only {
    padding: 0 !important;
  }

  &--large.ant-btn-icon-only {
    min-width: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    .anticon {
      transform: scale(1) !important;
    }
  }

  &--xl {
    height: 48px !important;
    padding: 14px 24px !important;
  }

  &--xl.ant-btn-icon-only {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px !important;
    padding: 0 !important;

    .anticon {
      width: 17px;
      height: 17px;
    }
  }
}
