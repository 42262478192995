@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'ccm-text';

.#{$name} {
  &--h5 {
    @include h5-regular();
  }

  &--text {
    @include text();
  }

  &--body {
    @include body-regular();
  }

  &--text-bold {
    @include text-bold();
  }

  &--description {
    @include description14($normal-8);
  }

  &--description-bold {
    @include description-bold($normal-8);
  }
}
