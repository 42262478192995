@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'ccm-indicator';

.#{$name} {
  display: inline-flex;
  align-items: center;
  padding: 0 $space-1;
  height: 24px;

  font-weight: 400;
  @include description-regular();
  border-radius: 2px;

  &--tooltip {
    &:hover {
      cursor: pointer;
    }
  }

  & .anticon {
    margin-left: 4px;
    color: $neutral3;
  }


  &__from {
    color: $neutral3;

    .#{$name}--in_progress & {
      color: $blue3;
    }

    .#{$name}--done & {
      color: $green3;
    }
  }

  &__to {
    color: $neutral2;

    .#{$name}--in_progress & {
      color: $blue2;
    }

    .#{$name}--done & {
      color: $green2;
    }
  }

  background-color: $neutral1;

  &--in_progress {
    background-color: $blue1;

    & .anticon {
      color: $blue3;
    }
  }

  &--done {
    background-color: $green1;

    & .anticon {
      color: $green3;
    }
  }

}
