@import "src/styles/variables";
@import "src/styles/mixins";

.info-drawer-content {

  .info-drawer-title {
    @include h3-regular($neutral5);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0 0 16px;
  }
  //Start Rewrite collapse style
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
  }

  .ant-collapse-expand-icon {
    display: flex;
  }

  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 10px 0 24px 0;
  }
  //End Rewrite collapse style

  .info-drawer-work-progress,
  .info-drawer-content-progress,
  .info-drawer-content-state {
    margin-bottom: 24px;
  }
  .info-drawer-prod-dates {
    margin-bottom: 32px;
  }

  .info-drawer-progress,
  .info-drawer-plans,
  .info-drawer-dates {
    margin-bottom: $space-5;
  }

  .ant-collapse-item {
    border-top: 1px solid $normal-4;
    border-bottom: 1px solid $normal-4;

    .anticon-right {
      & svg {
        transform: rotate(-90deg);
      }
    }
  }

  .ant-collapse-header {
    padding: 12px 0 !important;
  }

  .ant-collapse-content-box {
    padding: 0;
  }
}



