@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'ccm-search';

.#{$name} {
  .ant-input {
    padding: 6px 12px 6px 8px;
    font-size: 12px;
    line-height: 18px;
    background-color: $neutral1;
    border-color: $neutral1;
    border-radius: 4px;

    &::placeholder {
      color: $neutral3;
    }

    &:hover {
      border-color: $neutral3;
      box-shadow: none;

      &.ant-input-disabled {
        border-color: $neutral1;
      }
    }

    &:focus {
      background-color: $white;
      border-color: $neutral1;
      box-shadow: none;
    }

    &:disabled {
      color: $neutral2 !important;

      &::placeholder {
        $neutral2: #C8C6C5;
      }
    }

    &-lg {
      padding: 9px 12px;
      font-size: 14px;
      line-height: 20px;
      border-radius: 6px;
    }

  }

  &--xl {
    .ant-input {
      padding: 11px 12px 11px 16px;
      font-size: 16px;
      line-height: 24px;
      border-radius: 8px;
    }

    .ant-input-search-button {
      height: 48px !important;
      min-width: 48px !important;
      font-size: 16px;
      line-height: 24px;

      .anticon-search {
        display: flex;
        justify-content: center;

        svg {
          font-size: 24px;
        }
      }
    }

    &--icon-only {
      .ant-input-search-button {
        width: 48px !important;
        padding: 12px 24px;
      }

    }

  }
}

