@import 'src/styles/variables';
@import 'src/styles/mixins';

.check-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - 56px - 56px - 48px);
  overflow: hidden;

  &__block {
    display: flex;
    flex-direction: column;
    margin-bottom: $space-6;
  }

  &__wrapper {
    overflow: auto;
    @include scrollSmall();
  }

  &__item {
    margin-left: 0 !important;
    margin-bottom: $space-2;
    @include h5-regular($normal-9);

    &--title {
      font-weight: 400 !important;
      font-size: 20px !important;
      line-height: 28px !important;
      color: $neutral5 !important;
      margin-bottom: $space-4;
    }
  }
}
