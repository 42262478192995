@import "src/styles/variables";
@import "src/styles/mixins";

.participants-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 48px;

  &__title {
    @include h3-regular($neutral5);
  }

  &__divider {
    margin: 4px 0 32px 0;
    width: 100%;
    height: 3px;
    background-color: $main;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &--skeleton {
    display: flex;
    align-items: center;
    gap: 12px;

    .ant-skeleton-input {
      height: 50px !important;
    }
  }
}
