@import "src/styles/variables";
@import "src/styles/mixins";

.ccm-tabs {
  .ant-tabs-nav {
    height: 56px;
    margin: 0;

    &:before {
      border: none;
    }

    .ant-tabs-tab {
      &-btn {
        @include overline-bold($neutral5);
        text-transform: uppercase;
      }
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 24px;
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        @include overline-bold($neutral5);
        text-transform: uppercase;
      }
    }

    .ant-tabs-ink-bar {
      background-color: $neutral5;
    }
  }
  .ant-tabs-content {
    padding: 24px;
  }
  .ant-tabs-tabpane {
    height: 100%;
  }
  .ccm-tab-pane {
    &-title {
      margin-right: 4px;
      @include overline-bold($neutral5);
      text-transform: uppercase;
    }

    &-title-badge {
      display: flex;
      align-items: center;
    }
  }
}


