@import "src/styles/variables";
@import "src/styles/mixins";

.dashboard-gantt {
  display: flex;
  width: 100%;

  &-users {
    display: flex;
    flex-direction: column;
    width: 460px;
    min-width: 460px;
    background-color: $white;
    padding: 92px 40px 57px 32px;
    border-right: 1px solid $neutral1;
  }

  &__wrap {
    display: flex;
    width: 100%;
  }
}

.dashboard-gantt-chart {
  width: 100%;
  background-color: $white;
  overflow: hidden;

  &__container {
    position: relative;
    padding: 18px 20px;
    height: 100%;
    overflow-x: auto;
    @include scrollSmall();
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__today {
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: $neutral5;
  }
}
