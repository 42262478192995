
.create-free-task-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 32px;
  }
}
