@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'ccm-checkbox';
$group: 'ccm-checkbox-group';

.#{$name},
.#{$group} {
  .ant-checkbox {
    top: 0;
  }
  .ant-checkbox-inner {
    border-radius: 0;
    border-color: $neutral3;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    z-index: 5;
  }

  &--icon {
    .ant-checkbox {
      display: none;
    }
  }
}
