@import "src/styles/variables";
@import "src/styles/mixins";

.info-drawer-prod-dates {
  display: flex;
  flex-direction: column;

  &__label {
    margin-bottom: 8px;
    @include description-regular($neutral3);
  }

  &__block {
    display: inline-flex;
    align-items: center;
  }

  &__value {
    @include body-regular($neutral5);
  }

  &__days-left {
    @include description-regular($neutral3);
  }
}
