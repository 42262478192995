@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'ccm-drawer';

.#{$name} {
  .ant-drawer-body {
    @include scrollSmall();
  }
  .ant-drawer-header {
    padding: 16px 32px;
    border-color: $neutral1;

    .header-drawer {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &__wrap {
        display: inline-flex;
        align-items: center;
        width: 100%;

        .ant-btn-circle {
          margin-right: 16px;
        }
      }

      &__title {
        @include h4-regular($neutral5);
      }

      &__extra {
        display: flex;
        margin-left: auto;
        margin-right: 16px;
      }

      .close-button {
        width: 32px;
        min-width: 32px;

        &:hover {
          color: $neutral3;
        }
      }
    }
  }
  .ant-drawer-footer {
    padding: 0;
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 32px;

    .ant-btn-default {
      margin-right: 8px;
    }
  }
}

