.drawer-assignees {
  &__skeletons {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 24px;

    .ant-skeleton-input {
      width: 100% !important;
    }
  }
}
