@import "src/styles/variables";
@import "src/styles/mixins";

.gantt-item {
  position: absolute;
  height: 24px;
  border: 1px solid $main;
  border-radius: 4px;
  padding: 2px 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &__text {
    @include description-regular($neutral5);

  }
}
