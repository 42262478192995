@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'status-flat';

.#{$name} {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  border-radius: 24px;

  & .dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: $space-2;

    &--blocked {
      background-color: rgba(0, 0, 0, 0.85);
    }

    &--block,
    &--declined,
    &--cancelled,
    &--blocked {
      background-color: $red-7;
    }


    &--new {
      background-color: $normal-10;
    }

    &--in_progress,
    &--review,
    &--invited {
      background-color: $ccm-blue-7;
    }

    &--reopen,
    &--edits {
      background-color: $orange-7;
    }

    &--done,
    &--approved,
    &--registered {
      background-color: $green-7;
    }
  }

  &__value {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    white-space: pre;
  }

  &--blocked {
    color: rgba(0, 0, 0, 0.85);
  }

  &--block,
  &--declined,
  &--cancelled,
  &--blocked {
    color: $red-6;
  }

  &--new {
    color: $normal-7;
  }

  &--in_progress,
  &--review,
  &--invited {
    color: $ccm-blue-6;
  }

  &--reopen,
  &--edits {
    color: $orange-5;
  }

  &--done,
  &--approved,
  &--registered {
    color: $green-6;
  }
}
