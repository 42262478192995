@import "src/styles/variables";
@import "src/styles/mixins";

.gantt-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 35px;
  }

  &__row {
    position: relative;
    width: 100%;
    height: 24px;
  }

  .gantt-item {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}
