@import "src/styles/variables";
@import "src/styles/mixins";

.separate-task-discussion {
  width: 100%;
  padding: 16px 24px;
  border: 1px solid $neutral1;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background-color: $white;
    border: 1px solid $neutral1;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &--selected {
    background-color: $white;
    border: 1px solid $neutral1;
  }

  &__container {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__comment {
    @include body-regular($neutral5);
  }
}

.ft-discussion-head {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 12px;
}
