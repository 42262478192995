@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex: 1;
}

.wrap {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.image {
  width: 64px;
  height: 40px;
  margin-bottom: 8px;
}

.text {
  @include description-regular($neutral4);
}
