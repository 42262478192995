@import "src/styles/variables";

.plan-loading-overlay {
  position: fixed;
  top: 170px;
  height: calc(100vh - 170px);
}

.project-page-content {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 8px 24px 8px;

  &__loading {
    opacity: 0.25;
  }

  &--overlay {
    position: absolute;
    z-index: 2000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $normal-1;
    opacity: 0.25;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}
