@import "src/styles/variables";

.plan-toolbar {
  position: sticky;
  top: 113px;
  z-index: 10;
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  background-color: $neutral0;
  padding: 0 32px;
  border-bottom: 1px solid rgba(27, 25, 23, 0.1);

  &__expand {
    display: inline-flex;
    align-items: center;
  }

  &__missed {
    display: inline-flex;
    align-items: center;

    &-label {
      white-space: nowrap;
      margin: 0 8px;
    }
  }

  &__filters {
    display: inline-flex;
    max-width: 848px;

    .ccm-select,
    .ccm-search {
      min-width: 160px;
      max-width: 200px;
      margin-left: 16px;

      @media screen and (max-width: 1601px) {
        width: 160px;
      }
    }

    .ccm-search {
      @media screen and (max-width: 1601px) {
        width: 200px;
      }
    }

    .ccm-select {
      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__setting {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__edit {
    display: inline-flex;
  }

  &__buttons {
    display: inline-flex;
  }
}
