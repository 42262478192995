@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'course-tree-item';

.#{$name} {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  padding: 12px 16px 12px 24px;
  min-height: 64px;
  background-color: $neutral0;
  border: 1px solid  $neutral0;
  border-radius: 6px;

  &--hovered {
    position: relative;
    z-index: 2;
    cursor: pointer;
    border-color: $neutral3;
  }

  &--edit {
    &:hover {
      cursor: auto;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  &__checkbox-download {
    position: absolute;
    z-index: 4;
    left: 32px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;

    .ant-checkbox {
      top: 0 !important;
    }
  }

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 40px;
    padding-left: 72px;
  }

  &__main-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-inner {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  &__download-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    .anticon-download {
      color: $neutral5;
    }

    &:hover {
      background-color: $neutral1;

      .anticon-download {
        color: $neutral4;
      }
    }
  }

  &__extra-buttons {
    position: absolute;
    height: 64px;
    right: 100px;
    display: flex;
    z-index: 2000;
    align-items: center;
  }

  &__image-wrapper {
    width: 100%;
    height: 100%;
    margin-top: 16px;
    padding-left: 76px;
  }

  &--level-1 {
    padding-left: 64px;
    margin-top: 0;
  }

  &--level-2 {
    padding-left: 106px;
    margin-top: 0;
  }

  &--level-3 {
    padding-left: 148px;
    margin-top: 0;
  }

  &--level-4 {
    padding-left: 200px;
    margin-top: 0;
  }

  &--level-5 {
    padding-left: 262px;
    margin-top: 0;
  }

  &--level-6 {
    padding-left: 334px;
    margin-top: 0;
  }

  &__title {
    display: inline-flex;
    height: auto;
    width: 50vw;
    text-align: start;
    padding-left: 0 !important;
    @include h5-regular($neutral5);

    .#{$name}:hover & {
      color: $neutral4 !important;
      background-color: transparent !important;
    }
  }

  &__content-item {
    display: flex;
    flex-direction: column;
    padding-right: 92px;

    &:nth-child(2) {
      margin-top: $space-3;
    }

    &:not(:last-child) {
      margin-bottom: $space-4;
    }
  }

  &__content-title {
    display: inline-flex;
    align-items: center;
    height: 32px;
    @include description($normal-7);
  }

  &__content-value {
    @include text($normal-9);
  }

  &__toggle {
    align-self: baseline;

    .ccm-button {
      padding: 0 !important;
      color: $neutral3 !important;
    }
  }

  &__count {
    min-width: 40px !important;
    height: 18px;
    @include footnote($neutral3);

     .course-tree-item--level-3 & {
       margin-right: 10px;
     }
    .course-tree-item--level-4 & {
      margin-right: 10px;
    }
    .course-tree-item--level-5 & {
      margin-right: 10px;
    }
    .course-tree-item--level-6 & {
      margin-right: 10px;
    }
  }

  .ccm-element-type {
    margin: 0 12px;
  }

  &__indicators {
    display: flex;
    align-items: center;
    margin-left: auto;

    & .ccm-indicator:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__progress {
    width: 42px;
    margin: 0 16px;
    text-align: end;

    &-percent {
      color: $normal-7;
    }
  }

  .ccm-button--icon {
    width: 24px;
    min-width: 24px;
  }

  &__buttons {
    visibility: hidden;
    z-index: -2;
    display: flex;
    align-items: center;
    margin: 0 30px 0 auto;
  }

  &--hovered,
  &--focused {
    .#{$name}__buttons--showed {
      visibility: visible;
      z-index: 1;
    }
  }

  &__extra-buttons {
    &--delete {
      color: $red-6 !important;

      &:hover {
        color: $red-4 !important;
        background-color: transparent;
      }

      &-fake {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        color: $normal-6 !important;
        background-color: transparent !important;
        cursor: pointer;

        & svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    &--copy,
    &--edit {
      &:hover {
        color: $neutral4 !important;
        background-color: $white !important;
      }
    }
  }
}

.ccm-tree-list-item {
  display: flex;
  flex-direction: column;
}
