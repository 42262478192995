@import "src/styles/variables";
@import "src/styles/mixins";

.document-file-block {
  position: relative;
  display: flex;
  width: 100%;
  padding: 16px 48px 28px 24px;
  background-color: $main;
  border-radius: 8px;
  max-height: 237px;

  &__toggle {
    position: absolute !important;
    top: 0;
    right: 0;
  }

  &__image {
    img {
      max-width: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;

    a {
      text-decoration: underline;
    }

    p {
      @include h5-regular($black);
    }

    ul, ol, li {
      @include body-regular($neutral5);
    }
  }

  .ccm-title {
    padding: 0.6em 0 3px;
  }

  &__table {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    font-size: 14px;
    border-top: 1px solid $neutral4;
    line-height: 1.4;

    &-row {
      display: grid;
      grid-template-columns: repeat(auto-fit,minmax(10px,1fr));
      position: relative;
      border-bottom: 1px solid $neutral4;
    }
    &-cell {
      border-right: 1px solid $neutral4;
      padding: 6px 12px;
      overflow: hidden;
      outline: none;
      line-break: normal;
    }
  }
}
