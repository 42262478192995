@import "src/styles/variables";
@import "src/styles/mixins";

.course-import {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto auto;

  &__title {
    @include h5-regular($normal-9);
    margin-bottom: $space-1;
  }

  &__description {
    @include text($normal-7);
    margin-bottom: $space-4;
    width: 430px;
    text-align: center
  }

  &__buttons {
    flex-direction: row;

    & .ccm-button:first-child {
      margin-right: $space-4;
    }
  }
}
