@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'task-errors';

.#{$name} {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-shadow: inset 1px -1px 0 $normal-2;

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &-empty {
      height: auto;
    }
  }

  &-no-data__wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto auto;
  }

}
