.task-page-skeleton-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;

  .ant-skeleton-title {
    height: 24px !important;
    margin: 0 !important;
  }
}

.task-page-skeleton-tabs {
  &__block {
    display: flex;
    flex-direction: column;
   // padding: 0 24px;

    &:not(:last-child) {
      margin-bottom: 48px;
    }

    & .ant-skeleton {
      .ant-skeleton-title {
        margin: 0 !important;
      }
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      &:first-child {
        margin-bottom: 24px;
      }
    }
  }
}

.task-page-skeleton-editor {
  width: 100%;
  padding: 44px 64px;
  &__block {
    display: flex;
    flex-direction: column;
  }
  & .ant-skeleton {
    .ant-skeleton-title {
      margin: 0 !important;
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    &:first-child {
      margin-bottom: 24px;
    }
  }
}
