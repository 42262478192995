@import "src/styles/variables";
@import "src/styles/mixins";

.ccm-upload {

  &.ant-upload-drag {
    background-color: $white;
    border: 1px solid $neutral1;

    &:hover {
      border-color: $neutral5;
    }

    .ant-upload-btn {
      padding: 0;
    }
  }


  .ant-upload-drag-icon {
    .anticon-inbox {
      color: $neutral5 !important;
    }
  }

  .ant-upload-text {
    @include h5-regular()
  }

  & .ant-upload-hint {
    @include text($normal-7);
    font-size: 12px !important;
  }
}

.ccm-pictures-upload {
  &.ant-upload.ant-upload-drag.not-empty {
    height: unset;
  }
}
