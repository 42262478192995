@import "src/styles/variables";
@import "src/styles/mixins";

.info-drawer-problems {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  &__title {
    margin-left: 16px;
    @include overline($neutral5);
  }

  &__content {
    padding-left: 32px;
  }
}
