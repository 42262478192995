@import "src/styles/variables";
@import "src/styles/mixins";

.ft-panel-users {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  width: 100%;

  &__block {
    display: flex;
    flex-direction: column;
  }

  &__label {
    margin-bottom: 12px;
    @include description-regular($neutral3);
  }

  &__info {
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    &--author {
      &:hover {
        cursor: auto;
      }
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__user-name {
    margin-left: 8px;
    @include body-regular($neutral5);
  }
}
