
.status-list-page {
  display: flex;
  flex-direction: column;

  .status-list {
   &__top {
     display: flex;
     align-items: center;
     column-gap: 64px;
   }

  }
}
