@import "src/styles/variables";
@import "src/styles/mixins";

.ccm-timeline {

  .ant-timeline-item-head-blue {
    color: $neutral5;
    border-color: $neutral3;
    background: $neutral0;
  }
  & .ant-timeline-item-label {
    top: -6px !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $neutral3;
  }
  & .ant-timeline-item-head-custom {
    background: transparent;
  }
  &__text {
    @include text14($neutral5);
  }

  &__bold {
    @include text-bold($neutral5);
    margin-bottom: $space-2;
  }

  &__time {
    @include description($normal-7);
  }

  &__item {
    .ant-timeline-item-content {
      display: inline-flex;
      flex-direction: column;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $neutral5;
    }
  }

  &__title {
    @include text14($neutral5);
  }

  &__date {
    @include description($text-gray);
  }

  &-prod-dates,
  &-artefact-dates {
    .ant-timeline-item-content {
      display: flex;
      flex-direction: column;
    }
  }
}
