@import "src/styles/variables";

.content-toolbar {
  position: sticky;
  top: 113px;
  z-index: 10;
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 0 32px;
  border-bottom: 1px solid $neutral1;
  background-color: $neutral0;

  &__filters {
    display: flex;
    align-items: center;
    flex: 1;

    .ccm-select,
    .ccm-search {
      min-width: 140px;
      max-width: 200px;
      margin-right: 16px;
    }
  }

  &__actions {
    display: inline-flex;
    align-items: center;
  }

  &__edit {
    display: inline-flex;
  }

}
