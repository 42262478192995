@import "src/styles/variables";
@import "src/styles/mixins";

.skeleton-about {
  display: flex;
  flex-direction: column;

  .skeleton-row {
    margin-bottom: 32px;

    .ant-skeleton-title {
      margin-bottom: 0;
      height: 100%;
    }
  }
}

.skeleton-project-header {
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  border-bottom: 1px solid $neutral1;

  .ant-skeleton-title {
    margin: 0;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 56px;

    .ant-skeleton-title {
      height: 24px !important;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    min-height: 56px;
    column-gap: 16px;
  }
}

.skeleton-project-toolbar {
  display: flex;
  align-items: center;
  min-height: 56px;
  column-gap: 16px;
  border-bottom: 1px solid $neutral1;
  padding: 0 32px;

  .ant-skeleton-title {
    margin: 0;
  }
}

.skeleton-project-content {
  display: flex;
  flex-direction: column;
  padding: 0 24px;

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 56px;
    column-gap: 16px;

    .ant-skeleton-title {
      margin: 0;
    }
  }
}
