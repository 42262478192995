@import "src/styles/variables";
@import "src/styles/mixins";

.download-drawer-format-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  background-color: $neutral0;
  border-radius: 50%;

  .anticon {
    margin-bottom: 8px;
    font-size: 32px;
  }

  &__text {
    @include body-regular();
  }
}
