@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'video-player';

.#{$name} {
  display: flex;
  width: 100% !important;
  height: 100% !important;

  &-wrapper {
    &--full-screen {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 9999;
      display: flex;
      flex-direction: column;
      margin: 0 !important;
      background-color: $black;

      .#{$name} {
        height: calc(100% - 36px) !important;
      }
    }
  }
}
