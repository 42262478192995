@import "src/styles/variables";
@import "src/styles/mixins";

.plan-fact-tag {
  display: inline-flex;
  align-items: center;
  padding: 2px 12px;
  width: fit-content;
  @include description-regular();
  border-radius: 4px;

  &--level-1 {
    @include description-regular($green3);
    background-color: $green1;

    &.plan-fact-tag--flat {
      background-color: transparent;
      border: transparent;
      color: $green3;
      padding: 0;
    }
  }

  &--level-2 {
    @include description-regular($orange3);
    background-color: $orange1;

    &.plan-fact-tag--flat {
      background-color: transparent;
      border: transparent;
      color: $orange3;
      padding: 0;
    }
  }

  &--level-3 {
    @include description-regular($red3);
    background-color: $red1;

    &.plan-fact-tag--flat {
      background-color: transparent;
      border: transparent;
      color: $red3;
      padding: 0;
    }
  }

  &--level-4 {
    @include description-regular($neutral0);
    background-color: $neutral5;

    &.plan-fact-tag--flat {
      background-color: transparent;
      border: transparent;
      color: $neutral5;
      padding: 0;
    }
  }
}
