@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'ccm-version';

.#{$name} {
  display: flex;
  flex-direction: row;
  align-items: center;

  &-menu {
    min-width: 100px !important;
  }

  &__name {
    @include text();
    margin-right: 10px;

    &:first-letter {
      text-transform: capitalize
    }
  }

  & .ccm-select {
    min-width: 50px;
  }
}
