@import "src/styles/variables";
@import "src/styles/mixins";

.ant-drawer-body {
  //position: relative;
}

.form-add_employee {
  .organisation-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &__label {
      margin-bottom: 8px;
      @include description-regular($neutral3);
    }

    &__text {
      @include h4-regular($neutral5);
    }
  }
}
