@import "src/styles/variables";
@import "src/styles/mixins";

.comments {
  overflow: hidden;
  height: calc(100vh - 56px - 56px - 48px);
  padding-bottom: 50px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    padding-right: 12px;
    @include scrollSmall();
  }

  &__menus {
    display: inline-flex;
    margin-bottom: 16px;

    .ccm-dropdown {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}
