@import "src/styles/variables";
@import "src/styles/mixins";

.task-performers {
  display: flex;
  flex-direction: column;

  &__label {
    margin-bottom: 8px;
    @include description-regular($neutral3);
  }

  &__content {
    display: flex;
    flex-direction: column;

    .user-element {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
