@import "src/styles/variables";
@import "src/styles/mixins";

.learning-outcomes {
  display: flex;
  flex-direction: column;
  padding-left: 40px;

  &__wrap {
    height: 32px;
  }
}
