@import "src/styles/variables";
@import "src/styles/mixins";

.add-course-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  height: 100%;

  &__description {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;

    .add-course-form__title {
      margin-bottom: 16px;
    }

    .ccm-date-picker--production {
      margin-right: 16px;
    }

    .ccm-select--timeZone {
      margin-left: 8px;
    }
  }

  &__title {
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
    color: $neutral5;
  }

  //temp
  //& .ccm-date-picker {
  //  width: 70%;
  //}
}
