@import "src/styles/variables";
@import "src/styles/mixins";

.art-not-created {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__text {
    margin-top: 16px;
    @include description-regular($neutral3);
    text-align: center;
    max-width: 260px;
  }
}
