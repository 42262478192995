@import "src/styles/variables";
@import "src/styles/mixins";

.info-drawer-pop {
  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 264px;
    height: 100%;
    padding-right: 8px;
    overflow-y: auto;
    @include scrollSmall();

    &__row {
      display: inline-flex;
      align-items: center;
      min-height: 48px;

      .ccm-svg-inline {
        width: 14px;
        height: 14px;

        svg {
          width: 14px;
          height: 14px;

          path {
            fill: $normal-7;
          }
        }
      }
    }

    &__count {
      min-width: 30px;
      margin-left: $space-4;
      margin-right: $space-2;
      @include description($normal-7);
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }

    &__text {
      width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: $space-6;
      @include text($neutral5);
    }

    .ccm-tag {
      margin-left: auto;
    }

    &--loading {
      align-items: center;
      justify-content: center;
      height: 150px;
      font-size: 36px;

      .anticon-loading {
        color: $neutral5;
      }
    }
  }
}
