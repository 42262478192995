@import "src/styles/variables";
@import "src/styles/mixins";

.collapse-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__text {
    @include overline-bold($neutral5);
  }

  &__ex {
    display: flex;
    align-items: center;
  }
}
