@import 'src/styles/variables';
@import 'src/styles/mixins';

.ccm-editor-tool {
  display: inline-flex;
  position: absolute;
  height: 32px;
  background-color: $white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;

  &--showed {
    opacity: 1;
    visibility: visible;
    z-index: 3;
    transition: 0.3s;
  }
}
