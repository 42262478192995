@import "src/styles/mixins";

.info-drawer-content-state {
  display: flex;

  &__block {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin-bottom: 8px;
    @include description-regular($neutral3);
  }

  &__value {
    .status-flat {
      padding: 0;
    }
  }
}
