@import "src/styles/variables";

$name: 'layout';

.#{$name} {
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;

  &--auth {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: $white;
  }

  &--users {
    height: 100%;
    background-color: $neutral0;
  }
}
