@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'course-tree-plan-item';

.#{$name} {
  display: flex;
  flex-direction: column;
  padding: 12px 20px 12px 0;
  background-color: $neutral0;

  &:hover {
    cursor: pointer;
  }

  &__checkbox {
    width: 16px;
    height: 16px;
    margin-right: 32px !important;

    .#{$name}--level-1 & {
      margin-right: 64px !important;
    }

    .#{$name}--level-2 & {
      margin-right: 112px !important;
    }

    .#{$name}--level-3 & {
      margin-right: 160px !important;
    }

    .#{$name}--level-4 & {
      margin-right: 224px !important;
    }
  }

  &__checkbox-empty  {
    min-width: 16px;
    min-height: 16px;
  }

  &--edit {
    padding-left: 0;
  }

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__count {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    @include description($normal-7);
    min-width: 32px;

    .#{$name}--level-1 & {
      min-width: 48px;
    }
    .#{$name}--level-2 & {
      min-width: 48px;
    }
    .#{$name}--level-3 & {
      min-width: 64px;
    }
    .#{$name}--level-4 & {
      min-width: 80px;
    }
  }

  &__icon-wrap {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 16px 0 12px;
    width: 28px;
    min-width: 28px;
    height: 28px;
    background-color: $neutral1;
    border-radius: 50%;

    .ccm-svg-inline {
      margin: 0 !important;

      & svg, path {
        color: $normal-7;
        fill: $normal-7;
      }
    }
  }

  &__main-content {
    display: flex;
    flex-direction: column;

    &--base {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    display: block;
    @include h5-regular($neutral5);

    .#{$name}:hover & {
      color: $neutral4 !important;
      background-color: transparent !important;
    }
  }

  &__right {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
  }

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    color: $neutral5;
  }

  &__extra {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 12px;
  }

  &__progress {
    &__value {
      @include h5-regular($neutral5);
    }
    &__text {
      margin-left: 2px;
      @include footnote($neutral3);
    }
  }
}

.ex-item__inner {
  .ccm-svg-inline {
    margin: 0 !important;
  }
  .#{$name}--level-1 & {
    margin-left: 60px;
  }
  .#{$name}--level-2 & {
    margin-left: 60px;
  }
  .#{$name}--level-3 & {
    margin-left: 76px;
  }
  .#{$name}--level-4 & {
    margin-left: 76px;
  }
}
