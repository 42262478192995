@import "src/styles/variables";
@import "src/styles/mixins";

.user-item-info {
  display: inline-flex;
  align-items: center;

  &__inner {
    display: inline-flex;
    flex-direction: column;
    margin-left: 16px;
  }

  &__company {
    margin-bottom: 4px;
    @include description-regular($neutral3);
  }

  &__name {
    @include h5-regular($neutral5);
  }
}
