@import "src/styles/variables";
@import "src/styles/mixins";

.task-final-presentation {

  &__container {
    grid-area: view;
    padding: 16px 64px 14px 64px;
    background-color: $normal-1;
    border-radius: 20px 20px 0 0;
  }

  &__tabs {
    grid-area: tabs;
    background-color: $neutral0;
    box-shadow: inset 1px 0 0 #F7F6F9;
    height: calc(100vh - 60px);

    & .ant-tabs-nav,
    & .ant-tabs-content-holder {
      padding: 0;
    }

    & .ant-tabs-nav {
      padding: 0 $space-5;
    }

    .ant-tabs-tab {
      margin-right: $space-4;
    }

    .ant-tabs-content-holder {
      padding-bottom: 16px;
    }

    .ant-tabs-content {
      height: 100%;
      overflow-y: auto !important;
      @include scrollSmall();
    }

    & .editor {
      .cdx-input.image-tool__caption {
        display: none;
      }
    }
  }

  .ccm-tab--carousel {
    display: grid;
    grid-template-columns: 1fr 440px;
    column-gap: 64px;
    padding-bottom: 0 !important;
  }

  & .button-check {
    margin-right: $space-2;
  }
}
