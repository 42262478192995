@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'ccm-select';

.#{$name} {
  width: 100%;

  &--xl {
    .ant-select-selector {
      min-height: 48px !important;
      border-radius: 8px !important;
      padding-left: 16px !important;

      .ant-select-selection-search {
        margin-inline-start: 1px !important;
      }

      //.ant-select-selection-placeholder {
      //  padding-left: 6px !important;
      //}

      input {
        height: 46px !important;
      }

      & .ant-select-selection-placeholder,
      .ant-select-selection-item {
        font-size: 16px !important;
        line-height: 46px !important;
      }

      .ant-select-selection-overflow-item {
        .ant-select-selection-item {
          background-color: $neutral5;
          font-size: 16px;
          height: 32px;
          line-height: 32px !important;
          color: $neutral0;

          .ant-select-selection-item-remove {
            color: $neutral0;
          }
        }
      }
    }
  }

  &.ant-select-lg {
    .ant-select-selector {
      border-radius: 6px;
    }

    & .ant-select-selection-placeholder,
    .ant-select-selection-item {
      font-size: 14px;
    }
    .ant-select-selection-overflow-item {
      .ant-select-selection-item {
        background-color: $neutral5;
        font-size: 14px;
        line-height: 30px !important;
        color: $neutral0;

        .ant-select-selection-item-remove {
          color: $neutral0;
        }
      }
    }
  }

  &.ant-select-sm {
    & .ant-select-selection-placeholder,
    .ant-select-selection-item {
      font-size: 14px;
    }
    .ant-select-selection-overflow-item {
      .ant-select-selection-item {
        background-color: $neutral5;
        font-size: 12px;
        line-height: 16px !important;
        color: $neutral0;

        .ant-select-selection-item-remove {
          color: $neutral0;
        }
      }
    }
  }

  & .ant-select:not(.ant-select-customize-input),
  &.ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      background-color: $neutral1;
      border-color: $neutral1;

      .ant-select-selection-placeholder {
        color: $neutral3;
      }

      & .ant-select-selection-placeholder,
      .ant-select-selection-item {
        font-size: 12px;
      }
    }

    .ant-select-arrow {
      color: $neutral3;
    }

    .ant-select-selection-overflow-item {
      .ant-select-selection-item {
        background-color: $neutral5;
        font-size: 12px;
        line-height: 24px;
        color: $neutral0;

        .ant-select-selection-item-remove {
          color: $neutral0;
        }
      }
    }
  }

  & .ant-select-focused {
    .ant-select-selector {
      background-color: $white !important;
      border-color: $neutral1 !important;
      box-shadow: none !important;
    }
  }

  &.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) {
    .ant-select-selector {
      background-color: $orange1;
      border-color: $orange1 !important;

      .ant-select-selection-item {
        color: $orange3;
      }
    }
  }

  &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) {
    .ant-select-selector {
      background-color: $red1;
      border-color: $red1 !important;

      .ant-select-selection-item {
        color: $red3;
      }
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input),
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-status-warning,
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-status-error {
    .ant-select-selector {
      background-color: $white;
      box-shadow: none;
      border-color: $neutral1;
    }
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      background-color: $neutral0;
      border-color: $neutral1;

      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        color: $neutral2;
      }
    }
  }

  .ant-form-item-label > label {
    @include description-regular($neutral3);
  }
  .ant-form-item-explain-error {
    @include description-regular($red3);
  }
}
