@import "src/styles/variables";
@import "src/styles/mixins";

.service-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 384px;

  &__icon {
    display: flex;
    height: 70px;
    justify-content: center;
    padding: 5px;
  }

  &__title {
    @include h4-regular($neutral5)
  }

  &__description {
    @include description-regular($neutral3)
  }
}
