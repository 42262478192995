@import "src/styles/variables";
@import "src/styles/mixins";

.editor {
  position: relative;
  display: flex;
  flex: 1;
  min-width: 100%;
  width: 100%;

  .codex-editor {
    width: 100%;
  }

  .ce-inline-tool--comment {
    width: 26px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  @media (min-width: 651px) {
    .codex-editor--narrow .ce-toolbox .ce-popover {
      right: auto;
      left: -50px;
    }
  }

  div::selection,
  span::selection {
    color: $neutral5;
    background-color: $neutral1 !important;
  }

  .cdx-block {
    @include body-regular($neutral5);

    & .cdx-list__item {
      @include body-regular($neutral5);
    }
  }

  .tc-row-hidden {
    &:after {
      display: none;
    }
  }

  .ce-paragraph {
    span {
      white-space: inherit !important;
    }
  }

  .codex-editor__redactor {
    width: 100%;
    padding-bottom: 120px !important;
  }

  .ce-block__content {
    max-width: 100%;

    h1 {
      @include h1-medium();
    }

    h2 {
      @include h2-medium();
    }

    h3 {
      @include h3-medium();
    }

    h4 {
      @include h4-medium();
    }

    h5 {
      @include h5-medium();
    }

    h6 {
      @include body-regular();
      font-weight: 500;
    }
  }

  .ce-toolbar {
    right: auto !important;
  }

  .mark-comment {
    padding: 0;
    background-color: $orange1;

    &--active {
      background-color: $orange2;
    }

    &.mark-hidden {
      background-color: transparent;
    }

    &--active.mark-hidden {
      background-color: $orange2;
    }
  }

  .ce-inline-tool--comment {
    &:disabled {
      cursor: not-allowed;

      path {
        fill: $neutral3;
      }
    }
  }

  .image-tool__caption {
    display: none;
  }
}

.ct {
  z-index: 9999;
}
