@import "src/styles/variables";
@import "src/styles/mixins";

.global-task-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 866px;

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
  }

  &__label {
    margin-bottom: 8px;
    @include description-regular($neutral3);
  }

  &__editor-wrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    .ce-block__content,
    .ce-toolbar__content {
      max-width: 730px !important;
    }

    .editor .ce-toolbar {
      right: 0 !important;
    }

    .editor .codex-editor__redactor {
      min-height: 160px;
      padding-bottom: 24px !important;
      font-size: 16px;
      line-height: 24px;
      border-radius: 8px;
      background-color: $white;
    }
  }


}
