@import "src/styles/variables";
@import "src/styles/mixins";

.upload-avatar {
  width: 200px;
  height: 200px;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: $neutral1;
  }

  & img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }

  .anticon-plus {
    font-size: 16px;
  }

  &:hover {
    cursor: pointer;
  }
}
