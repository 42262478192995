@import "src/styles/variables";
@import "src/styles/mixins";

.resource-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 108px;

  &__title {
    margin-bottom: $space-3;
    @include h5-regular($neutral5);
  }

  &__input {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__cancel-button {
    border-color: $red-3 !important;
  }

  &__error {
    margin-bottom: $space-1;
    @include text($red-6);
  }

  &__validation {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }

  //& .text-field {
  //  background-color: $neutral0;
  //  margin-bottom: $space-1;
  //  border-color: $neutral5;
  //
  //  &:hover {
  //    background-color: $neutral0;
  //    border-color: $neutral5;
  //    box-shadow: none;
  //  }
  //}
}
