@import "src/styles/variables";
@import "src/styles/mixins";

.download-drawer-elements {
  display: flex;
  align-items: center;
  height: 64px;
  width: 100%;

  .ccm-checkbox {
    .ant-checkbox + span {
      @include body-regular($neutral5);
    }
  }

  .ccm-badge .ant-badge-count {
    margin-left: 4px;
    background-color: $white;
    color: $neutral5;
    border: 1px solid $neutral2;
  }

  &__radio,
  &__select {
    margin-left: auto;
  }

  &__video-format {
    margin-right: 12px;
    @include description-regular($neutral5);
  }

  &__select {
    display: inline-flex;
    align-items: center;
  }
}
