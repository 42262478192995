@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.upload-wall {
  display: flex;
  flex-direction: column;
  width: 100%;


  &__label {
    margin-bottom: 8px;
    @include description-regular($neutral3);

    &-alt {
      @include overline($neutral3);
    }
  }

  &__wrapper {
    display: flex;
  }


  &__text {
    margin-top: 8px;
    @include description-regular($neutral3);
  }

  &__size {
    margin-left: 25px;
    max-width: 309px;
    @include description-regular($neutral5);
  }

  &-select {
    width: 160px;
    height: 160px;

    .ant-upload-select {
      display: flex;
      width: 160px;
    }

    .upload-wall__button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 160px;
      height: 160px;
      margin: 0;
      border: 1px dashed $neutral2;
      border-radius: 2px;
    }

    &:hover {
      cursor: pointer;
      background-color: $white;
    }

    &--empty {
      width: 100%;

      &-short {
        width: 160px;
      }

      .ant-upload-select {
        display: flex;
        width: 100%;
        .ant-upload {
          width: 100%;
        }
      }

      .upload-wall__button {
        width: 100%;

        &-progress-wrap {
          width: 88px;
          height: 22px;
          margin-top: 8px;

          .ant-progress-bg {
            height: 2px !important;
          }
        }
      }
    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 866px;

    .upload-wall-preview {
      margin: 0 16px 16px 0;

        &:nth-child(5n) {
          margin-right: 0;
        }
    }
  }
}
