@import "src/styles/variables";
@import "src/styles/mixins";

.account-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &-header {
    display: flex;
    align-items: center;
    padding: 12px 32px;
    border-bottom: 1px solid $neutral1;

    &__title {
      @include h4-regular();
    }
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 560px;
    height: 100%;
    margin: 0 auto;
    padding: 24px 32px 48px;
  }

  .status-flat {
    margin-bottom: 8px;
  }

  .account-form {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__actions {
      display: flex;
      align-items: center;
      margin-top: 52px;
    }
  }
}
