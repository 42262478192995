@import "src/styles/variables";
@import "src/styles/mixins";

.progress-tooltip {
  position: absolute;
  min-width: 42px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $black75;
  border-radius: 2px;

  &:after {
    position: absolute;
    bottom: -4px;
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;

    border-top: 4px solid $black75;
  }

  &__content {
    @include text($normal-1);
  }
}
