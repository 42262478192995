@import "src/styles/variables";
@import "src/styles/mixins";

.ccm-tree-add-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 74px 0 96px;
  margin-top: 2px;
  min-height: 48px;
  background-color: $neutral0;

  .ccm-svg-inline {
    margin-right: $space-1;

    & svg,
    path {
      color: $neutral5;
      fill: $neutral5;
    }
  }

  &--1 {
    min-height: 56px;
    margin-top: 0;
    padding-left: 72px + 64px;
  }

  &--2 {
    margin-top: 0;
    padding-left: 72px + 106px;
  }

  &--3 {
    margin-top: 0;
    padding-left: 72px + 148px;
  }

  &--4 {
    margin-top: 0;
    padding-left: 72px + 200px;
  }

  &--5 {
    margin-top: 0;
    padding-left: 72px + 262px;
  }

  &--5 {
    margin-top: 0;
    padding-left: 72px + 334px;
  }

  &--main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    min-height: 56px;
    margin-top: 2px;
    border-radius: 8px;

    &-text {
      @include overline($neutral5);
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    .ccm-button {
      width: 100%;
      justify-content: center;
      background-color: $neutral0;
    }

    & .ccm-button {
      display: inline-flex;
      align-items: center;

      .anticon-plus,
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
  &--element  {
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 0 16px 0 100px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    background-color: #ECE9E5;
  }
}
