@import 'src/styles/variables';
@import 'src/styles/mixins';

$name: 'sidebar';

.page-layout {
  min-height: 100vh;

  &__header {
    display: flex;
    width: 40px;
    justify-content: center;
    padding-top: 8px;
    background-color: $neutral5;

    & .ant-btn-link {
      color: $normal-1;
    }

    //&:hover {
    //  background-color: $neutral4;
    //  cursor: pointer;
    //}
  }
}

.#{$name} {
  background-color: $neutral0 !important;

  &__header {
    display: inline-flex;
    justify-content: center;
    padding-top: 8px;
    width: 100%;
    //height: 48px;
    & .ant-btn:hover {
      background: #FFFFFF;
      border-radius: 40px;
    }

    &-number {
      @include description($normal-7);
    }

    &-content {
      display: none;
    }
  }

  &__content {
    display: none;
  }

  &--opened {
    .#{$name}__header {
      display: flex;
      //height: 48px;
      padding: 8px 24px 0 24px;
      align-items: center;

      &-content {
        display: flex;
        //width: 100%;
      }
    }

    .#{$name}__trigger {
      margin-left: auto;
      color: $neutral5;
    }

    .#{$name}__content {
      display: flex;
      flex-direction: column;
    }
  }

  &.ant-layout-sider-collapsed {
    cursor: pointer;
  }
}
