@import "src/styles/variables";

.ccm-tree {
  &-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: $neutral0;
  }

  &-list {
    width: 100%;
  }

  &-list-item {
    background: $neutral0;
  }
}


