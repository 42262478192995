@import "src/styles/variables";
@import "src/styles/mixins";

.dashboard-widget {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 120px;
  padding: 12px 24px 8px 16px;
  background-color: $main;
  border-radius: 8px;

  &__bottom {
    display: inline-flex;
    justify-content: flex-end;
  }

  &__label {
    @include description-regular($neutral3);
  }

  &__value {
    font-weight: 300;
    font-size: 72px;
    line-height: 64px;
    color: $neutral5;

    &--warning {
      color: $orange3;
    }

    &--danger {
      color: $red3;
    }

    &--gray {
      color: $neutral3;
    }
  }
}
