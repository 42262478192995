@import "src/styles/variables";
@import "src/styles/mixins";

.free-task-panel {
  display: flex;
  flex-direction: column;
  padding:  0 32px 16px 32px;

  .ft-panel-users {
    margin-bottom: 24px;
  }
  .ft-panel-date {
    margin-bottom: 40px;
  }
}
