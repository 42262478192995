@import "src/styles/variables";
@import "src/styles/mixins";

.view-free-task-page__editor-wrap {
  position: relative;
  margin-bottom: 34px;
  //max-height: 640px;
  //overflow: hidden;
  //@include scrollSmall();

  &--opened {
    max-height: min-content;
  }

  .ce-block__content,
  .ce-toolbar__content {
    margin-left: 68px;
  }

  .editor .ce-toolbar {
    right: 0 !important;
  }

  .editor .codex-editor__redactor {
    min-height: 350px !important;
    padding-bottom: 24px !important;
    font-size: 16px;
    line-height: 24px;
    border-radius: 8px;
    background-color: $white;
  }

  .editor-overlay {
    position: absolute;
    z-index: 3;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 120px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    mix-blend-mode: normal;
  }
}
