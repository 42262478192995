@import "src/styles/variables";

.info-drawer-header {
  display: flex;
  width: 100%;
  align-items: center;

  &__inner {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
