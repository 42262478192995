@import "src/styles/variables";
@import "src/styles/mixins";

.ccm-select-label {
  display: inline-flex;
  align-items: center;

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    margin-right: 8px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  &__text {
    @include description-regular($neutral5);
  }
}
