@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'ccm-radio';

.#{$name} {
  .ant-radio-wrapper {
    & .ant-checkbox-inner {
      border-radius: 0 !important;
    }

    &:focus-within {
      box-shadow: none !important;
    }

    &:focus-within {
      box-shadow: none !important;
    }
  }

  &--small {
    &.ant-radio-group-small .ant-radio-button-wrapper {
      color: $neutral5 !important;
      border-color: $neutral1;
      background-color: $white !important;
      font-size: 12px;
      line-height: 22px;
    }
  }

  .ant-radio-button-wrapper {
    &:first-child {
      border-radius: 8px 0 0 8px;
    }
    &:last-child {
      border-radius: 0 8px 8px 0;
    }
    &:focus-within {
      box-shadow: none !important;
    }

    &-checked {
      color: $neutral5 !important;
      border-color: $neutral3 !important;;
      background-color: $white !important;

      input:-webkit-autofill {
        transition: background-color 600000s 0s, color 600000s 0s;
      }

      &:not(.ant-radio-button-wrapper-disabled):before {
        background-color: $neutral3;
      }
    }
  }


  &--full {
    display: flex;
    width: 100%;

    .ant-radio-button-wrapper {
      display: flex;
      width: 100%;
      & span:nth-last-child(1) {
        width: 100%;
        text-align: center;
      }
    }
  }

  &.ant-radio-group-solid {
    .ant-radio-button-wrapper {
      &:focus-within {
        box-shadow: none !important;
      }

      &-checked {
        color: $white !important;
        border-color: $neutral5 !important;
        background-color: $neutral5 !important;
        box-shadow: none !important;

        &:focus-within {
          box-shadow: none !important;
        }
      }

      &-disabled {
        &:before {
          background-color: $normal-4 !important;
        }
      }
    }
  }

  &.ant-radio-group-outline {
    .ant-radio-input:focus + .ant-radio-inner {
      box-shadow: none;
    }
    .ant-radio-checked {
      .ant-radio-input {
        &:focus {
          outline: none;
        }
      }
      .ant-radio-inner {
        background-color: $neutral5;
        &:after {
          width: 6px;
          height: 6px;
          margin: 0;
          transform: translate3d(-50%, -50%, 0);
          border-radius: 50%;
          background-color: $neutral0;
          transition: none;
        }
      }
      &:after {
        animation: none;
      }
    }
  }
}

