@import "../../../styles/variables";
@import "../../../styles/mixins";

.create-task-select-user {
  .ant-drawer-body {
    padding: 0;
  }
  &__content {
    display: flex;
    flex-direction: column;
    padding: 16px 32px;
  }

  .select-user-multiple {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
    }
    .ant-checkbox-group-item {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      margin-right: 0;
      padding: 7px 16px 7px 8px;
      border: 1px solid transparent;

      &:hover {
        background-color: $neutral0 ;
        border-radius: 4px;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    .ant-checkbox-wrapper:after {
      display: none;
    }
  }
}
