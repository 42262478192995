
.ccm-svg-inline {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: inherit;
    height: inherit;
  }
}
