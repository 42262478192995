@import "src/styles/variables";
@import "src/styles/mixins";

.elements-dropdown {
  width: fit-content !important;

  .anticon {
    margin-left: 8px;
    font-size: 10px;

    svg {
      width: 12px !important;
      height: 12px !important;
    }
  }


  &--opened {
    border-color: $neutral4 !important;
    background-color: $white !important;
    color: $neutral4 !important;
  }
}

