@import "src/styles/variables";
@import "src/styles/mixins";

.wrapper {
  width: 416px;
  display: flex;
  flex-direction: column;
  padding: 32px 32px 24px;
}

.content {
  margin-bottom: $space-5;
}

.icon {
  width: 22px;
  height: 22px;
}

.iconWarning {
  font-size: 22px;
  color: $danger;
  margin-right: $space-4;
}

.title {
  @include h5-regular($neutral5);
}

.text {
  @include description-regular($neutral3);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
