@import "src/styles/variables";

@mixin h1-medium($color: $neutral5) {
  font-weight: 500;
  font-size: 56px;
  line-height: 64px;
  color: $color;
}

@mixin h1-regular($color: $neutral5) {
  font-weight: 300;
  font-size: 56px;
  line-height: 64px;
  color: $color;
}

@mixin h2-medium($color: $neutral5) {
  font-weight: 500;
  font-size: 40px;
  line-height: 56px;
  color: $color;
}

@mixin h2-regular($color: $neutral5) {
  font-weight: 400;
  font-size: 40px;
  line-height: 56px;
  color: $color;
}

@mixin h3-medium($color: $neutral5) {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: $color;
}

@mixin h3-regular($color: $neutral5) {
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: $color;
}

@mixin h4-medium($color: $neutral5) {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: $color;
}

@mixin h4-regular($color: $neutral5) {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: $color;
}

@mixin h5-medium($color: $neutral5) {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $color;
}

@mixin h5-regular($color: $neutral5) {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: $color;
}

@mixin body-regular($color: $neutral5) {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $color;
}

@mixin body-regular-short($color: $neutral5) {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: $color;
}

@mixin text-bold($color: $normal-12) {
  font-weight: 700;
  font-size: 13px;
  line-height: 22px;
  color: $color;
}

@mixin text($color: $normal-12) {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $color;
}

@mixin text14($color: $normal-12) {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $color;
}

@mixin description-bold($color: $normal-12) {
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  color: $color;
}

@mixin description($color: $normal-12) {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $color;
}

@mixin description14($color: $normal-12) {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $color;
}

@mixin description-regular($color: $normal-12) {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color;
}

@mixin description-short($color: $normal-12) {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $color;
}

@mixin overline($color: $normal-12) {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: $color;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

@mixin overline-bold($color: $normal-12) {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: $color;
  text-transform: uppercase;
}

@mixin footnote($color: $normal-12) {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $color;
}

@mixin scrollHidden() {
  &::-webkit-scrollbar {
    width: 0;
  }
}

@mixin scrollSmall() {
  &::-webkit-scrollbar {
    width: 4px !important;
    height: 8px !important;
  }

  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: $normal-4

  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $neutral5;
  }
}

@mixin btn() {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
}

@mixin limit-text-rows($lines-number:3) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines-number;
  -webkit-box-orient: vertical;
}

