@import "src/styles/variables";

.tasks-page-filter {
  position: sticky;
  top: 0;
  z-index: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 12px 32px;
  border-bottom: 1px solid $neutral1;
  background-color: $neutral0;

  .ccm-select {
    width: 180px;
    margin-right: 16px;
  }
  .ccm-search {
    width: 180px;
  }
}
