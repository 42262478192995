@import "src/styles/variables";

.login-page {

  &__title {
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
    color: $neutral5;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & .text-field--email,
    & .text-field--password {
      width: 100%;
    }

    .button-login {
      margin-bottom: 30px;
      width: 100%;
    }
  }

  &__form {
    width: 400px;
  }

  & .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
}
