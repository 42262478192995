@import "src/styles/variables";
@import "src/styles/mixins";

.info-drawer-user {
  &-list {
    display: flex;
    flex-direction: column;
  }

  &-group {
    display: flex;
    flex-direction: column;
    &__title {
      margin-bottom: 16px;
      @include h4-regular($neutral5);
    }

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  &-block {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &__empty {
      @include h5-regular($neutral3);
    }

    &__wrapper {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
    .ccm-avatar {
      min-width: 48px;
      margin-right: 16px;
    }
    &__sub-title {
      margin-bottom: 12px;
      @include overline($neutral3);
    }
    &__link {
      @include body-regular($neutral5);
      &-text {
        margin-right: 6px;
      }
    }
    &__info {
      width: 100%;
      display: flex;
      flex-direction: column;

      &-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    &__company {
      margin-bottom: 4px;
      @include description-regular($neutral3);
    }
    &__name {
      display: block;
      width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include h5-regular($neutral5);
    }
  }
}
