@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.import-instructions {
  overflow-y: auto;

  & .ant-drawer-body {
    @include scrollSmall;
  }

  &__title {
    @include h5-regular($neutral5)
  }

  &__text {
    @include text($neutral5);

    & ul {
      list-style-type: disc;
      list-style-position: inside;
    }
  }

  &__download {
    padding: 16px 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: $neutral0;

    &-text {
      @include text($neutral5);
    }
  }
}
