@import 'src/styles/variables';
@import 'src/styles/mixins';

.dashboard-task-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $space-4 $space-5;
  background: $white;
  margin-bottom: 2px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }

  &__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__left,
    &__right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 96px;
  }

  &__artefact-type {
    display: inline-flex;
    align-items: center;
    width: 300px;
    min-width: 300px;
    margin-right: 8px;

    @media screen and (min-width: 1701px) {
      margin-right: 80px;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    &__name {
      @include body-regular($neutral5);
    }
  }

  &__img-type {
    svg {
      path {
        fill: $neutral3;
      }
    }
  }

  &__name-value {
    max-width: 740px;
  }

  &__name,
  &__deadlines,
  &__comments,
  &__iteration {
    @include body-regular($neutral5);
    white-space: nowrap;
  }

  &__comments {
    min-width: 48px;
  }

  &__number {
    min-width: 32px;
    @include text($neutral3);
  }

  &__img-type {
    @include body-regular($neutral5);
    display: flex;
    align-items: center;

    & svg {
      height: 16px;
      width: 16px;
    }
  }

  &__users {
    width: 100%;
    max-width: 140px;
    display: flex;
    align-items: center;
    margin-right: 24px;

    @media screen and (min-width: 1701px) {
      margin-right: 80px;
    }
  }

  &__date {
    display: inline-flex;
    white-space: nowrap;
    align-items: center;
    width: 100px;
    min-width: 100px;
    margin-right: 12px;

    @media screen and (min-width: 1701px) {
      margin-right: 80px;
    }
  }

  &__deadlines {
    display: inline-flex;
    align-items: center;
    width: 170px;
    min-width: 170px;
    margin-right: 24px;

    &:last-child {
      margin-right: 36px;
    }

    @media screen and (min-width: 1701px) {
      margin-right: 80px;
    }
  }

  &__status {
    display: inline-flex;
    align-items: center;
    width: 130px;
    min-width: 130px;

    .status-flat {
      padding: 0;
    }
  }

  &__course-link {
    @include text($neutral5);
  }
}
