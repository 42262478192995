@import "src/styles/variables";
@import "src/styles/mixins";

.task-draft-editing {
  &__container {
    grid-area: view;
    background-color: $normal-1;
    padding: 16px 64px 14px 64px;
    border-radius: 20px 20px 0 0;
  }

  & .ant-tabs-content {
    overflow-y: auto !important;
    @include scrollSmall();
  }

  &__tabs {
    grid-area: tabs;
    background-color: $neutral0;
    box-shadow: inset 1px 0 0 #F7F6F9;
    height: calc(100vh - 60px);

    & .ant-tabs-nav,
    & .ant-tabs-content-holder {
      padding: 0;
    }

    .ant-tabs-content-holder {
      padding-bottom: 16px;
    }

    & .ant-tabs-nav {
      padding: 0 $space-5;
    }

    .ant-tabs-tab {
      margin-right: $space-4;
    }

    .ant-tabs-content {
      height: 100%;
      padding: 0 $space-5;
      overflow-y: auto !important;
      @include scrollSmall();
    }

    & .editor {
      .cdx-input.image-tool__caption {
        display: none;
      }
    }
  }

  .ccm-tab--video-file {
    display: grid;
    grid-template-columns: 1fr 400px;
    column-gap: 64px;
    padding-bottom: 0 !important;

    &--percent {
      display: flex;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      align-items: center;
      justify-content: center;
      color: rgba(0, 0, 0, 0.65);
    }
  }

  &__new-version {
    & .ccm-button {
      width: 100%;
    }
  }
}
