@import "src/styles/variables";
@import "src/styles/mixins";

.project-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 8px;
}

.project-table-head {
  display: flex;
  align-items: center;
  padding: 11px 12px;
  @include overline($neutral3);

  @media screen and (min-width: 1601px) {
    padding: 11px 36px;
  }
}

.project-table-cell {
  &__number {
    min-width: 48px;
    max-width: 80px;
  }

  &__icon {
    min-width: 28px;
  }

  &__element {
    min-width: 200px;
    width: 100%;
    max-width: 257px;
  }

  &__task-type {
    min-width: 200px;
    width: 100%;
    max-width: 253px;
  }

  &__iteration {
    min-width: 80px;
    width: 100%;
    max-width: 114px;

    @media screen and (max-width: 1500px) {
      width: 80px;
    }
  }

  &__team {
    min-width: 90px;
    width: 100%;
    max-width: 164px;

    @media screen and (max-width: 1500px) {
      width: 90px;
    }
  }

  &__deadline {
    min-width: 165px;
    max-width: 245px;
    width: 100%;
  }

  &__changed {
    min-width: 85px;
    width: 100%;
    max-width: 118px;

    @media screen and (max-width: 1500px) {
      width: 85px;
    }
  }

  &__status {
    min-width: 120px;
  }
}

.project-table-row {
  display: flex;
  align-items: center;
  padding: 11px 12px;
  border: 1px solid transparent;

  @media screen and (min-width: 1601px) {
    padding: 11px 36px;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid $neutral3;
    border-radius: 6px;
  }

  &:active {
    background-color: $white;
  }

  .project-table-cell__number {
    @include footnote($neutral3);
  }

  .art-type-icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background-color: $neutral1;
    border-radius: 50%;

    &-s {
      display: none;
    }
  }

  .project-table-cell__element {
    @include body-regular($neutral5);
  }

  .art-type-name {
    margin-left: 8px;
    @include body-regular($neutral5);
  }

  .art-iter {
    margin-left: 4px;
    @include body-regular($neutral5);
  }

  .last-state-updated {
    margin-left: 2px;
    @include body-regular($neutral5);
  }

  .status-flat {
    padding: 0;
  }
}
