$header-height: 48.1px; //Так надо =)
$header-height-nav: 140px;
$filter-height: 134px;

// ===UI COLORS===

$white: #ffffff;
$black: #000000;
$black6: rgba(0, 0, 0, 0.06);
$black25: rgba(0, 0, 0, 0.25);
$black45: rgba(0, 0, 0, 0.45);
$black65: rgba(0, 0, 0, 0.65);
$black75: rgba(0, 0, 0, 0.75);
$black85: rgba(0, 0, 0, 0.85);

$main: #ece9e5;

$violet: #0e0b3c;
$text-gray: rgba(0, 0, 0, 0.45);
$text-error: #ff4d4f;
$main-blue: #5149d3;

$divider: rgba(0, 0, 0, 0.06);
$light-gray: #f0f2f5;

$ccm-blue-1: #f3f3fc;
$ccm-blue-2: #dbdbf6;
$ccm-blue-3: #b6b6ed;
$ccm-blue-4: #9792e5;
$ccm-blue-5: #746ddc;
$ccm-blue-6: #5149d3;
$ccm-blue-7: #413aa9;
$ccm-blue-8: #2e2981;
$ccm-blue-9: #1f1b56;
$ccm-blue-10: #0e0b3c;

$neutral0: #F8F8F8;
$neutral1: #E9E9E9;
$neutral2: #C8C6C5;
$neutral3: #848484;
$neutral4: #605F5E;
$neutral5: #1E1E1E;
$neutral7: #898990;

$red1: #F8EAE7;
$red2: #E89C8B;
$red3: #D73C1D;
$red4: #8D2008;
$red5: #511102;

$orange1: #F3DFC2;
$orange2: #EDC687;
$orange3: #E29416;
$orange4: #CC6E00;
$orange5: #7E3D01;

$green1: #D5E4CA;
$green2: #ADCC94;
$green3: #65B129;
$green4: #2E6006;
$green5: #2D3C22;

$blue1: #DAD9EB;
$blue2: #A7A6D5;
$blue3: #5149D3;
$blue4: #191078;
$blue5: #0C0035;

$red-1: #fff1f0;
$red-2: #f6c6c8;
$red-3: #f69fa1;
$red-4: #f6767a;
$red-5: #ee4c5c;
$red-6: #e5263e;
$red-7: #c31834;
$red-8: #a00d2c;
$red-9: #7d0727;
$red-10: #5b0724;

$orange-1: #fff7e6;
$orange-2: #f6dfbc;
$orange-3: #f6ce95;
$orange-4: #f6ba6f;
$orange-5: #ee9f4f;
$orange-6: #e98529;
$orange-7: #c7681c;
$orange-8: #a44d15;
$orange-9: #823a15;
$orange-10: #5f2815;

$green-1: #f6ffed;
$green-2: #d2eebf;
$green-3: #b2e393;
$green-4: #91d76a;
$green-5: #70c44c;
$green-6: #52ad3d;
$green-7: #3b9621;
$green-8: #287319;
$green-9: #195115;
$green-10: #102e15;

$normal-1: #ffffff;
$normal-2: #f7f6f9;
$normal-3: #f2f1f4;
$normal-4: #edecef;
$normal-5: #dddbe2;
$normal-6: #b9b9c0;
$normal-7: #898990;
$normal-8: #595862;
$normal-9: #44434d;
$normal-10: #292832;
$normal-11: #23222c;
$normal-12: #191822;
$normal-13: #080715;

$danger: #ff4d4f;

$space-1: 4px;
$space-2: 8px;
$space-3: 12px;
$space-4: 16px;
$space-5: 24px;
$space-6: 32px;
$space-7: 48px;
$space-8: 64px;
$space-9: 80px;
$space-10: 96px;
$space-11: 120px;


