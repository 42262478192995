@import "src/styles/variables";
@import "src/styles/mixins";

.upload-wall-preview {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  padding: 0 8px;
  background-color: $neutral0;
  border-radius: 2px;

  &-mask {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144px;
    height: 144px;
    background-color: $neutral1;
    opacity: 0.95;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  &__text {
    @include description-regular($neutral4);
    text-align: center;

    &-wrap {
      margin-top: 8px;
      text-align: center;
      height: 60px;
    }
  }
}
