@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'ccm-time-picker';

.#{$name} {

  &.ant-picker {
    background-color: $neutral1;
    border-color: $neutral1;

    .ant-picker-suffix {
      color: $neutral3;
    }

    input {
      @include footnote($neutral5);

      &::placeholder {
        @include footnote($neutral3);
      }
    }

    &:hover {
      border-color: $neutral3;
      box-shadow: none;
    }
  }

  &.ant-picker-large {
    input {
      font-size: 14px;
      line-height: 20px;

      &::placeholder {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &.ant-picker-xl {
    input {
      font-size: 16px;
      line-height: 24px;

      &::placeholder {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &.ant-picker-status-warning {
    background-color: $orange1;
    border-color: $orange1;
  }

  &.ant-picker-status-error {
    background-color: $red1;
    border-color: $red1;
  }

  &.ant-picker-focused {
    background-color: $white;
    border-color: $neutral1;
    box-shadow: none;
  }

  &.ant-picker-disabled {
    background-color: $neutral0 !important;
    border-color: $neutral1 !important;

    :hover {
      border-color: $neutral1;
    }

    .ant-picker-suffix {
      color: $neutral2;
    }

    input {
      color: $neutral2;

      &::placeholder {
        color: $neutral2;
      }
    }
  }
}
