@import "src/styles/variables";
@import "src/styles/mixins";

.add-course {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 24px 32px;
  }
}
