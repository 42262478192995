
.create-art-type {
  width: 520px;

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
  }
}

