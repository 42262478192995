@import 'src/styles/variables';
@import 'src/styles/mixins';

.task-text {
  &__view {
    grid-area: view;
    display: flex;
    background-color: $normal-1;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
    height: calc(100vh - 80px);

    &-wrap {
      padding: 24px 64px;
      overflow-y: auto;
      width: 100%;
      @include scrollSmall();
    }
  }

  &__tabs {
    grid-area: tabs;
    background-color: $neutral0;
    box-shadow: inset 1px 0 0 #f7f6f9;
    height: calc(100vh - 56px);

    & .ant-tabs-nav {
      padding: 0 $space-5;
    }

    .ant-tabs-content {
      height: 100%;
      padding: 0 $space-5;
      overflow: hidden;
      overflow-y: auto !important;
      @include scrollSmall();
    }

    & .editor {
      .cdx-input.image-tool__caption {
        display: none;
      }
    }
  }
}
