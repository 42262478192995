@import "src/styles/variables";
@import "src/styles/mixins";

$name: 'ccm-dropdown';

.#{$name} {
  .anticon {
    font-size: 10px;
  }

  .ant-space-item {
    @include footnote($neutral5);
    font-feature-settings: 'tnum' on, 'lnum' on;
  }

  &--link {
    @include footnote($neutral5);

    &:hover {
      color: $neutral3;
    }

    &.#{$name}--disabled {
      color: $neutral2 !important;
    }
  }

  &--button {
    &.ant-btn.ant-btn-default {
      padding: 0 16px;
      border-color: $neutral2;
      border-radius: 24px;
      font-size: 12px;
      line-height: 18px;
      color: $neutral5;
      text-transform: none !important;
      font-weight: 400;
      background-color: transparent;

      &:hover {
        background-color: transparent;
        border-color: $neutral4;
        color: $neutral4;
      }

      &:disabled {
        background-color: transparent !important;
        border-color: $neutral2 !important;
        color: $neutral2 !important;
      }
    }

    &.ant-btn-sm.ant-btn-default {
      padding: 0 12px;
    }

    &.ant-btn-lg.ant-btn-default {
      padding: 0 24px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .ant-dropdown-menu {
    padding: 0;
    border-radius: 4px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);

    .ant-dropdown-menu-item {
      padding: 5px 18px;
      font-size: 14px;
      line-height: 20px;
      color: $neutral5;

      &:first-child {
        border-radius: 4px 4px 0 0;
      }
      &:last-child {
        border-radius:0 0 4px 4px
      }
    }
  }
}

