@import "src/styles/variables";

.state-manage-block {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 8px;

  &--active {
    background-color: $white;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  &__actions {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  &__content {

    .ccm-table {
      .ant-table-tbody {
        tr {
          &:hover {
            cursor: auto;
            box-shadow: none !important;
            td {
              box-shadow: none !important;
            }
          }
        }

        .ant-table-cell {
          padding-top: 12px;
          padding-bottom: 12px;
          border: none;
          box-shadow: none !important;
        }
      }

      .ccm-table__empty {
        min-height: 150px;
      }
    }

  }
}
