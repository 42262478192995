@import "src/styles/variables";
@import "src/styles/mixins";

.download-drawer-content {
  display: flex;
  flex-direction: column;
  padding: 24px 32px;

  .download-drawer-format-view {
    margin: 0 auto 21px auto;
  }

  &__title {
    margin-bottom: 8px;
    @include h3-regular();
  }

  .download-drawer-version {
    margin-bottom: 24px;
  }

  &__sub-title {
    margin-bottom: 12px;
    @include overline($neutral5);
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
}
