.ccm-modal--edit-issue {
  //padding: 16px;
  & .ant-modal-content {
    display: flex;
    flex-direction: column;
    padding: 16px;
  }
  display: flex;
  flex-direction: column;

}
