@import "src/styles/variables";
@import "src/styles/mixins";

.ccm-material-item {
  padding: 24px;
  gap: 12px;
  border: 1px solid $neutral2;
  border-radius: 8px;
  margin-bottom: 16px;
  //margin: 0 24px 16px 24px;

  &__video {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    &__title {
      @include h5-regular($neutral5);

      & a {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      & .ccm-svg-inline {
        margin-right: 12px;

        & svg {
          width: 20px;
          height: 20px;
          fill: $neutral5 !important;
        }
      }
    }

    &__info {
      @include text($neutral3);
      text-transform: uppercase;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;

    &__title {
      @include h5-regular($neutral5);
      display: flex;
      flex-direction: row;
      align-items: center;

      & a {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      & .ccm-svg-inline {
        margin-right: 12px;

        & svg {
          width: 20px;
          height: 20px;
          fill: $neutral5 !important;
        }
      }
    }
  }

  &__document {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;

    &__title {
      @include h5-regular($neutral5);
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__editor {
      .editor {
        .codex-editor__redactor {
          padding-bottom: 0 !important;
        }
      }

      & .ce-block {
        & .cdx-block {
          padding-top: 0;
          padding-bottom: 0;
          word-break: break-all;
        }
      }
    }
  }
}
