.container {
  width: 520px;
}

.header {
  padding: 0 48px;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 32px 64px;
}
