@import "src/styles/mixins";

.status-list-edit {
  display: flex;
  width: 100%;

  &__left,
  &__right {
    flex: 1;
    height: calc(100vh - 113px - 32px - 32px);
  }

  &__right {
    overflow: hidden;
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    overflow: auto;
    height: calc(100vh - 113px - 32px - 32px);
    @include scrollSmall();
  }
}
