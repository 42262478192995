@import "src/styles/variables";
@import "src/styles/mixins";

.main-layout {
  min-height: 100vh !important;
  padding-left: 64px;
}

.ant-layout-sider.main-sidebar {
  position: fixed !important;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;

  background-color: $neutral0;
  border-right: 1px solid $neutral1;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }
}

.main-sidebar-header {
  display: inline-flex;
  align-items: center;

  padding: 36px 10px 48px 10px;
  //margin-bottom: 53px;

  &__logo {
    width: 44px;
    height: 44px;

    &-wrap {
      overflow: hidden;
      width: 44px;
      height: 44px;

      .main-sidebar--opened & {
        width: 200px;
      }
    }

    &--external {
      height: 44px;
      width: 44px;

      .main-sidebar--opened & {
        width: 200px;
      }
    }
  }

  &__title {
    flex: auto;
    min-width: 0;
    margin-left: 10px;
    @include h4-regular($black);
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 0;

    .main-sidebar--opened & {
      opacity: 1;
      transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
    }
  }
}

.main-sidebar-footer {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: 36px;
  padding: 8px 8px 8px 12px;

  &:hover {
    background-color: $neutral1;
    cursor: pointer;
  }

  .ccm-avatar {
    min-width: 40px;
  }

  &__user {
    display: flex;
    flex-direction: column;
    opacity: 0;
    min-width: 0;
    width: 0;
    overflow: hidden;

    .main-sidebar--opened & {
      opacity: 1;
      width: 160px;
      margin-left: 12px;
      transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
    }

    &-role {
      width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      @include footnote($neutral3);
      white-space: pre;
    }

    &-name {
      width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      line-height: 20px;
      color: $neutral5;
      white-space: pre;
    }
  }

  .ccm-dropdown-menu {
    position: absolute;
    left: 10px;
    bottom: 60px;

    .main-sidebar--opened & {
      left: 86px;
      bottom: 31px;
    }
  }
}



